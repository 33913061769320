import request from '@/utils/request'
const Qs = require('qs')

// 获取列表
export function list(data) {
  return request({
    url: '/rumpson-system/rest/sysMenu/list',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 获取树
export function tree(data) {
  return request({
    url: '/rumpson-system/rest/sysMenu/tree',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 获取实例
export function getById(data) {
  return request({
    url: '/rumpson-system/rest/sysMenu/getById',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 保存
export function save(data) {
  return request({
    url: '/rumpson-system/rest/sysMenu/save',
    method: 'post',
    data: Qs.stringify(data)
  })
}
// 删除
export function del(data) {
  return request({
    url: '/rumpson-system/rest/sysMenu/delete',
    method: 'post',
    data: Qs.stringify(data)
  })
}
