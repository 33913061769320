<template>
  <el-dialog
    title="学生基本信息"
    :visible.sync="visible"
    width="800px"
    :before-close="handleClose"
  >
    <el-form ref="addForm" label-width="150px" style="margin:0 auto;">
      <el-row>
        <el-col :span="24">
          <el-form-item label="学号：">
            <el-input v-model="currModel.studentNo" size="small" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="姓名：">
            <el-input v-model="currModel.name" size="small" auto-complete="false" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系方式：">
            <el-input v-model="currModel.tel" size="small" auto-complete="false" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="年级：">
            <el-input v-model="currModel.grade" size="small" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="班级：">
            <el-input v-model="currModel.cls" size="small">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="紧急联系人：">
            <el-input v-model="currModel.contact" size="small" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="紧急人联系方式：">
            <el-input v-model="currModel.contactTel" size="small">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="排序：">
            <el-input-number v-model="currModel.orderBy" size="small" style="width: 100%;" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="状态：">
            <el-switch v-model="currModel.status" size="small" :active-value="1" :inactive-value="0" active-color="#409EFF" inactive-color="#F56C6C" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose()">取 消</el-button>
      <el-button size="small" type="primary" @click="doSave()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { save } from '@/api/school-bus/student'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default: () => {
        return { id: null }
      }
    }
  },
  data() {
    return {
      currModel: {}
    }
  },
  watch: {
    model(newVal) {
      this.currModel = newVal
    }
  },
  methods: {
    handleClose() {
      this.$emit('closeDialog')
    },
    doSave() {
      this.currModel.children = []
      save(this.currModel).then(res => {
        this.$message.success('保存成功！')
        this.$emit('closeDialog', true)
      }).catch((err) => {
        console.log(err)
        this.$message.error('保存失败！')
      })
    }
  }
}
</script>
<style scoped lang="scss">
</style>
