<template>
  <el-dialog
    title="模块/应用基本信息"
    :visible.sync="visible"
    width="800px"
    :before-close="handleClose"
  >
    <el-form ref="addForm" label-width="90px" style="margin:0 auto;">
      <el-row>
        <el-col :span="12">
          <el-form-item label="应用名称：">
            <el-input v-model="appModel.appName" size="small" auto-complete="false" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="路由地址：">
            <el-input v-model="appModel.path" size="small" auto-complete="false" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="页面：">
            <el-input v-model="appModel.page" size="small" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="应用图标：">
            <el-input v-model="appModel.icon" size="small">
              <el-button slot="append" :icon="appModel.icon" size="small" @click="showIconDialog" />
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="应用排序：">
            <el-input-number v-model="appModel.sort" size="small" style="width: 100%;" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="应用状态：">
            <el-switch v-model="appModel.status" size="small" :active-value="1" :inactive-value="0" active-color="#409EFF" inactive-color="#F56C6C" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="备注：">
            <el-input v-model="appModel.remark" type="textarea" size="small" :rows="5" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose()">取 消</el-button>
      <el-button size="small" type="primary" @click="doSave()">确 定</el-button>
    </span>
    <icon-chooser :visible="iconDialogVisible" @closeDialog="closeIconDialog" @confirm="setIcon" />
  </el-dialog>
</template>

<script>
import { tree, save } from '@/api/system/app'
import iconChooser from '@/components/icon-chooser'
export default {
  components: {
    iconChooser
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    app: {
      type: Object,
      default: () => {
        return { id: null }
      }
    }
  },
  data() {
    return {
      iconDialogVisible: false,
      appModel: {}
    }
  },
  watch: {
    app(newVal) {
      this.appModel = newVal
    }
  },
  methods: {
    showIconDialog() {
      this.iconDialogVisible = true
    },
    closeIconDialog() {
      this.iconDialogVisible = false
    },
    setIcon(icon) {
      this.appModel.icon = icon
    },
    handleClose() {
      this.$emit('closeDialog')
    },
    doSave() {
      this.appModel.children = []
      save(this.appModel).then(res => {
        this.$message.success('保存成功！')
        this.$emit('closeDialog', true)
      }).catch((err) => {
        console.log(err)
        this.$message.error('保存失败！')
      })
    }
  }
}
</script>
<style scoped lang="scss">
</style>
