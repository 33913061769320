<template>
  <div class="form-header">
    <slot />
  </div>
</template>
<script>
export default {
}
</script>
<style scoped lang="scss">
  .form-header {
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    margin-bottom: 5px;
    padding-left: 20px;
    border-bottom: solid 1px #eee;
  }
</style>
