<template>
  <div class="content-container">
    <div class="toolbar-box">
      <el-form ref="loginForm" :model="searchParam" :rules="rules">
        <el-row>
          <el-col :span="8">
            <el-form-item label="线路名称：" prop="line">
              <el-select v-model="searchParam.line" style="width: 200px;" @change="changeLine">
                <el-option value="" label="不限制"></el-option>
                <el-option v-for="line in lines" :key="line.id" :value="line.id" :label="line.lineName"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="站点名称：">
              <el-select v-model="searchParam.stop" style="width: 200px;">
                <el-option value="" label="不限制"></el-option>
                <el-option v-for="stop in stops" :key="stop.id" :value="stop.id" :label="stop.stopName"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="类别：">
              <el-select v-model="searchParam.type" style="width: 200px;">
                <el-option value="" label="不限制"></el-option>
                <el-option v-for="typ in types" :key="typ.id" :value="typ.id" :label="typ.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16">
            <el-form-item label="订票时间：">
              <el-date-picker
                v-model="searchParam.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                align="right"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="  ">
              <el-button type="normal" size="normal" @click="doSearch()">
                <i class="icon iconfont-system sousuo" />
                <span>查询</span>
              </el-button>
              <el-button v-loading="expLoading" type="default" size="normal" @click="doExport()">
                <i class="icon iconfont-system daochu" />
                <span>导出</span>
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="table-container">
      <el-table
        id="data-table"
        ref="treeTable"
        v-loading="loading"
        border
        size="small"
        highlight-current-row
        :row-key="'id'"
        :data="tableData"
        :row-class-name="tableRowClassName"
        :header-cell-style="{ background: '#FAFAFA' }"
      >
        <!-- 多行表头 -->
        <el-table-column align="center" label="兰州天立学校校车站点及学生信息表">
          <el-table-column align="right" label="线路名称：">
            <el-table-column align="right" label="类别：">
              <el-table-column type="index" prop="id" label="序号" width="100" align="center"> </el-table-column>
            </el-table-column>
          </el-table-column>

          <el-table-column align="left" :label="baseInfo.line">
            <el-table-column align="left" :label="baseInfo.type">
              <el-table-column align="center" prop="studentName" label="学生姓名" width="250"> </el-table-column>
            </el-table-column>
          </el-table-column>

          <el-table-column align="right" label="站点名称：">
            <el-table-column align="right" label="车牌号：">
              <el-table-column align="center" prop="studentTel" label="学生联系方式" width="120"> </el-table-column>
            </el-table-column>
          </el-table-column>

          <el-table-column align="left" :label="baseInfo.stop">
            <el-table-column align="left" :label="baseInfo.vehicleNo">
              <el-table-column align="center" prop="endStop" label="目的站点" width="300"> </el-table-column>
            </el-table-column>
          </el-table-column>

          <el-table-column align="right" label="日期：">
            <el-table-column align="right" label="跟车老师姓名及联系方式：">
              <el-table-column prop="grade" label="年级" width="120" align="center"> </el-table-column>
              <el-table-column prop="cls" label="班级" width="120" align="center"> </el-table-column>
            </el-table-column>
          </el-table-column>

          <el-table-column align="left" :label="baseInfo.date">
            <el-table-column align="left" :label="baseInfo.teacher">
              <el-table-column prop="remark" label="备注" width="350" align="center"></el-table-column>
            </el-table-column>
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import searchBox from '@/views/components/search-box.vue'
import { all as allLines } from '@/api/school-bus/line'
import { getStopsByLineId } from '@/api/school-bus/lineStop'
import { zip, exp, search as searchOrder } from '@/api/school-bus/order'

export default {
  name: 'Table',
  components: {
    searchBox
  },
  data() {
    return {
      tableName: '兰州天立学校校车站点及学生信息表',
      tableData: [],
      lines: [],
      stops: [],
      types: [
        { id: 1, name: '回家' },
        { id: 2, name: '返校' },
        { id: 3, name: '往返' }
      ],
      searchParam: {
        line: '',
        stop: '',
        type: '',
        time: []
      },
      rules: {
        line: [{ required: true, trigger: 'blur', message: '请选择线路' }]
      },
      baseInfo: {
        line: '',
        type: '',
        stop: '',
        vehicleNo: '',
        date: '',
        teacher: ''
      },
      loading: false,
      expLoading: false
    }
  },
  created() {
    this.getAllLines()
    this.searchParam.time = [this.$global.monday(), this.$global.sunday()]
  },
  methods: {
    getAllLines() {
      allLines().then((res) => {
        if (res && res.status === 200) {
          this.lines = res.data

          const line = res.data[0]
          this.getStopsByLine(line.id)
        }
      })
    },
    getStopsByLine(lineId) {
      getStopsByLineId({ lineId: lineId }).then((res) => {
        if (res && res.status === 200) {
          this.stops = res.data
        }
      })
    },
    // 下拉事件
    changeLine(val) {
      this.getStopsByLine(val)
    },
    doSearch() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
          const params = {
            line: '',
            stop: '',
            type: '',
            time: this.searchParam.time
          }
          const currLines = this.lines.filter(item => item.id === this.searchParam.line)
          const currStops = this.stops.filter(item => item.id === this.searchParam.stop)
          const currTypes = this.types.filter(item => item.id === this.searchParam.type)
          if (currLines && currLines.length > 0) {
            const lineName = currLines[0].lineName
            params.line = lineName
            this.baseInfo.line = lineName
          }
          if (currStops && currStops.length > 0) {
            const stopName = currStops[0].stopAliasName
            params.stop = stopName
            this.baseInfo.stop = stopName
          }
          if (currTypes && currTypes.length > 0) {
            const typeName = currTypes[0].name
            params.type = typeName
            this.baseInfo.type = typeName
          }

          searchOrder(params).then((res) => {
            if (res && res.status === 200) {
              this.tableData = res.data
            }
            this.loading = false
          }).catch((err) => {
            console.error(err)
            this.loading = false
          })
        }
      })
    },
    doExport() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          if (this.searchParam.line) {
            this.expLoading = true
            const params = {}
            const currLines = this.lines.filter(item => item.id === this.searchParam.line)
            params.line = currLines[0].lineName
            if (this.searchParam.stop) {
              const currStops = this.stops.filter(item => item.id === this.searchParam.stop)
              params.stop = currStops[0].stopAliasName
            }
            if (this.searchParam.type) {
              const currTypes = this.types.filter(item => item.id === this.searchParam.type)
              params.type = currTypes[0].name
            }
            params.time = this.searchParam.time

            zip(params).then((resZip) => {
              if (resZip && resZip.status === 200) {
                const data = resZip.data
                const zipFileName = data.zipFileName
                const zipFilePath = data.zipFilePath
                exp({ zipFilePath: zipFilePath }).then((res) => {
                  // 加上{ type: "application/octet-stream;" }
                  const blob = new Blob([res], { type: 'application/octet-stream;' })
                  const url = window.URL.createObjectURL(blob)
                  const link = document.createElement('a')
                  link.href = url
                  link.setAttribute('download', zipFileName)
                  document.body.appendChild(link)
                  link.click()
                  link.remove()
                  this.expLoading = false
                }).catch((errr) => {
                  this.expLoading = false
                })
              }
            }).catch((errr) => {
              this.expLoading = false
            })
          }
        }
      })
      // else {
      //   import('@/utils/table2Excel.js').then(excel => { // 导入js模块
      //     const multiHeader = [
      //       [this.tableName, '', '', '', '', '', ''],
      //       ['线路名称', this.baseInfo.line, '站点名称', this.baseInfo.stop, '日期', '', this.baseInfo.date],
      //       ['类别', this.baseInfo.type, '车牌号', this.baseInfo.vehicleNo, '跟车老师姓名及联系方式', '', this.baseInfo.teacher]
      //     ]
      //     const header = ['序号', '学生姓名', '学生联系方式', '目的站点', '年级', '班级', '备注'] // 导出excel 的标题
      //     const filterVal = ['id', 'studentName', 'studentTel', 'endStop', 'grade', 'cls', 'remark'] // 每个标题对应的字段

      //     // eslint-disable-next-line no-sparse-arrays
      //     const merges = ['A1:G1', 'E2:F2', 'E3:F3'] // 合并单元格的数据
      //     const list = (this.tableData || []).map((item, key) => { // 通过 map 方法遍历，组装数据成上面的格式
      //       return {
      //         id: key + 1 || '',
      //         studentName: item.studentName ? item.studentName : '',
      //         studentTel: item.studentTel ? item.studentTel : '',
      //         endStop: item.endStop ? item.endStop : '',
      //         grade: item.grade ? item.grade : '',
      //         cls: item.cls ? item.cls : '',
      //         remark: item.remark ? item.remark : ''
      //       }
      //     })
      //     if (list) {
      //       const data = this.formatJson(filterVal, list) // 生成json数据
      //       excel.export_json_to_excel({
      //         multiHeader,
      //         header,
      //         data,
      //         merges,
      //         filename: '校车站点及学生信息表'
      //       })
      //     } else {
      //       alert('暂无无数据')
      //     }
      //   })
      // }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 === 0) {
        return 'odd'
      }
      return ''
    }
  }
}
</script>
<style scoped lang="scss">
.content-container {
  .toolbar-box {
    display: flex;
    flex-direction: row;
    min-width: 1440px;
    // height: 40px;
    background: #fff;
    padding: 10px 0 0 0 ;
    border-bottom: solid 2px #eee;
  }
}

::v-deep .el-form-item {
  display: flex !important;
}

::v-deep .el-form-item__label {
  display: block;
  width: 150px !important;
}

::v-deep .el-form-item__content {
  margin-left: 3px !important;
  margin-right: 3px !important;
}
</style>
