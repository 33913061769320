import request from '@/utils/request'
const Qs = require('qs')

// 所有
export function getStopsByLineId(data) {
  return request({
    url: '/school-bus/rest/lineStop/getStopsByLineId',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 保存
export function save(data) {
  return request({
    url: '/school-bus/rest/lineStop/save',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 删除
export function del(data) {
  return request({
    url: '/school-bus/rest/lineStop/delete',
    method: 'post',
    data: Qs.stringify(data)
  })
}
