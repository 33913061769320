<template>
  <div class="content-container">
    <div class="tab-bar-container">
      <el-tabs v-model="activeName">
        <el-tab-pane v-for="(item, index) in tabList" :key="index" :label="item.label" :name="item.label"> </el-tab-pane>
      </el-tabs>
    </div>
    <div class="tab-content-container">
      <log-login v-if="activeName === '登录日志'" />
      <log-command v-if="activeName === '操作日志'" />
      <log-error v-if="activeName === '错误日志'" />
    </div>
  </div>
</template>

<script>
import LogLogin from './log-login.vue'
import LogCommand from './log-command.vue'
import LogError from './log-error.vue'
import { mapGetters } from 'vuex'

export default {
  components: { LogLogin, LogCommand, LogError },
  data() {
    return {
      activeName: '登录日志',
      tabList: [
        { label: '登录日志', type: 0 },
        { label: '操作日志', type: 1 },
        { label: '错误日志', type: 2 }
      ]
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList() {
      return {
        viewBtn: this.vaildData(this.permission.log_api_view, false)
      }
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
  .content-container {
    padding-left: 10px;
  }
</style>
