<template>
  <div class="content-container">
    <div class="toolbar-container">
      <search-box v-model="searchKey" style="float: right; margin-right: 35px;" @doSearch="doSearch" />
      <el-button type="primary" size="mini" style="float: left;" @click="doAdd()">
        <i class="icon iconfont-system xinzeng" />
        <span>添加</span>
      </el-button>
    </div>
    <div class="table-container">
      <el-table
        ref="treeTable"
        v-loading="loading"
        border
        size="small"
        highlight-current-row
        :row-key="'id'"
        :data="tableData"
        :row-class-name="tableRowClassName"
        :default-expand-all="false"
        :header-cell-style="{ background: '#FAFAFA' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column width="55" type="selection" reserve-selection align="center"></el-table-column>
        <el-table-column type="index" prop="id" label="序号" width="55" align="center"> </el-table-column>
        <el-table-column prop="name" label="线路名称" align="left"> </el-table-column>
        <el-table-column prop="firstStop" label="始发站" width="300" align="left"> </el-table-column>
        <el-table-column prop="lastStop" label="终点站" width="300" align="left"> </el-table-column>
        <el-table-column prop="orderBy" label="排序" width="80" align="center"> </el-table-column>
        <el-table-column prop="status" label="状态" :formatter="statusFormat" width="100" align="center">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" size="mini" :active-value="1" :inactive-value="0" active-color="#409EFF" inactive-color="#F56C6C" disabled />
          </template>
        </el-table-column>
        <el-table-column width="300" label="操作" align="center">
          <template v-if="scope.row.lineName" slot-scope="scope">
            <el-button type="text" size="mini" @click="edit(scope.row)">
              <i class="icon iconfont-system xiugai"></i>
              <span style="margin-left: 5px;">修改</span>
            </el-button>
            <el-button type="text" size="mini" @click="rel(scope.row)">
              <i class="icon iconfont-system xiugai"></i>
              <span style="margin-left: 5px;">关联站点</span>
            </el-button>
            <el-button type="text" size="mini" @click="delApp(scope.row)">
              <i class="icon iconfont-system shanchu"></i>
              <span style="margin-left: 5px;">删除</span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      class="page-container"
      :current-page="currPage"
      :page-size="pageSize"
      :total="total"
      :align="'right'"
      layout="total, sizes, prev, pager, next"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    >
    </el-pagination>
    <edit-line :visible="showEditDialog" :model="currModel" @closeDialog="closeEditDialog" />
    <edit-line-stop :visible="showRelDialog" :model="currModel" @closeDialog="closeRelDialog" />
  </div>
</template>

<script>
import searchBox from '@/views/components/search-box.vue'
import editLine from './edit-line.vue'
import editLineStop from './edit-line-stop.vue'
import { page, del } from '@/api/school-bus/line'
import { getStopsByLineId } from '@/api/school-bus/lineStop'
export default {
  name: 'User',
  components: {
    searchBox,
    editLine,
    editLineStop
  },
  data() {
    return {
      tableData: [],
      currPage: 1,
      pageSize: 10,
      total: 0,
      searchKey: '',
      showEditDialog: false,
      showRelDialog: false,
      currModel: {},
      loading: false
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.loading = true
      const params = {
        pageIndex: this.currPage,
        pageSize: this.pageSize,
        key: this.searchKey
      }
      page(params).then((res) => {
        if (res && res.status === 200) {
          const lines = res.data.records
          lines.forEach((line) => {
            line.name = line.lineName
            line.children = []
            getStopsByLineId({ lineId: line.id }).then((res2) => {
              if (res2 && res2.status === 200) {
                const stops = res2.data
                stops.forEach((stop) => {
                  stop.name = stop.stopName
                })
                line.children = res2.data
              }
            })
          })

          this.tableData = res.data.records
          this.total = res.data.total
        }
        this.loading = false
      }).catch((err) => {
        console.error(err)
        this.loading = false
      })
    },
    handleCurrentChange(current) {
      this.currPage = current
      this.loadData()
    },
    handleSizeChange(size) {
      this.pageSize = size
      this.loadData()
    },
    statusFormat(row) {
      if (row && row.status) {
        if (row.status === 1) {
          return '启用'
        }
      } else {
        return '禁用'
      }
    },
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 === 0) {
        return 'odd'
      }
      return ''
    },
    handleSelectionChange() {

    },
    doSearch(searchKey) {
      this.searchKey = searchKey
      this.loadData()
    },
    doAdd() {
      this.currModel = {
        status: 1
      }
      this.showEditDialog = true
    },
    edit(entity) {
      this.currModel = entity
      this.showEditDialog = true
    },
    rel(entity) {
      this.currModel = entity
      this.showRelDialog = true
    },
    delApp(entity) {
      this.$confirm('删除后无法恢复, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        del({ id: entity.id }).then(res => {
          if (res && res.status === 200) {
            this.currPage = 1
            this.loadData()
            this.$message({ message: '删除成功', type: 'success' })
          } else {
            this.$message({ message: '删除失败', type: 'error' })
          }
        }).catch((err) => {
          console.error(err)
          this.$message({ message: '删除失败', type: 'error' })
        })
      }).catch(() => {
        // 取消
      })
    },
    closeEditDialog(refresh) {
      this.showEditDialog = false
      if (refresh) {
        this.loadData()
      }
    },
    closeRelDialog(refresh) {
      this.showRelDialog = false
      if (refresh) {
        this.loadData()
      }
    }
  }
}
</script>
<style scoped lang="scss">
</style>
