import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const global = {
  state: {
    token: null,
    userinfo: null,
    tab: null,
    closedtab: null,
    dynamicRoutes: null,
    searchState: null,
    map: null
  },
  getters: {
    token: (state) => {
      return state.token
    },
    userInfo: (state) => {
      return state.userInfo
    },
    tab: (state) => {
      return state.tab
    },
    closedtab: (state) => {
      return state.closedtab
    },
    dynamicRoutes: (state) => {
      return state.dynamicRoutes
    },
    searchState: (state) => {
      return state.searchState
    },
    map: (state) => {
      return state.map
    }
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setUserInfo(state, userinfo) {
      state.userinfo = userinfo
    },
    setTab(state, tab) {
      state.tab = tab
    },
    setClosedTab(state, tab) {
      state.closedtab = tab
    },
    setDynamicRoutes(state, dynamicRoutes) {
      state.dynamicRoutes = dynamicRoutes
    },
    setSearchState(state, searchState) {
      state.searchState = searchState
    },
    setMap(state, map) {
      state.map = map
    }
  }
}

export default new Vuex.Store({
  modules: {
    global
  }
})
