<template>
  <div class="content-container">
    <div v-loading="loading" class="content">
      <div class="left-container">
        <search-box width="290px" style="margin: 2px 5px 2px 5px;" @doSearch="doSearchRole" />
        <el-tree
          ref="eltree"
          node-key="id"
          icon-class="custom"
          highlight-current
          class="data-tree"
          :data="roles"
          :props="treeprops"
          :default-expand-all="true"
          :expand-on-click-node="false"
          :filter-node-method="_filterNode"
          @node-click="changeRole"
        >
          <!-- @node-contextmenu="_showContextMenu" -->
          <!-- 根目录 -->
          <span v-if="node.level === 1" slot-scope="{ node, data }" class="tree-item-content item-root">
            <i v-if="node.expanded" class="icon iconfont-system wenjianjiazhankai"></i>
            <i v-else class="icon iconfont-system wenjianjiaguanbi"></i>
            <span :title="data[treeprops.label]" class="label">{{ data[treeprops.label] }}</span>
            <!-- 弹出菜单 -->
            <el-dropdown trigger="click" @command="handleCommand($event, data)">
              <i class="icon iconfont-system gengduo"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="add-role"><i class="icon iconfont-system xinzeng" /> 新增子角色</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
          <!-- 角色目录 -->
          <span v-else-if="data.children && data.children.length > 0" slot-scope="{ node, data }" class="tree-item-content item-folder">
            <i v-if="node.expanded" class="icon iconfont-system wenjianjiazhankai"></i>
            <i v-else class="icon iconfont-system wenjianjiaguanbi"></i>
            <span :title="data[treeprops.label]" class="label">{{ data[treeprops.label] }}</span>
            <!-- 弹出菜单 -->
            <el-dropdown v-if="data.roleType && data.roleType !== '0'" trigger="click" @command="handleCommand($event, data)">
              <i class="icon iconfont-system gengduo"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="add-role"><i class="icon iconfont-system xinzeng" /> 新增子角色</el-dropdown-item>
                <el-dropdown-item command="edit-role"><i class="icon iconfont-system xiugai" /> 编辑角色 </el-dropdown-item>
                <el-dropdown-item command="del-role"><i class="icon iconfont-system shanchu" /> 删除角色</el-dropdown-item>
                <el-dropdown-item command="role-menu"><i class="icon iconfont-system quanxian" /> 权限分配</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
          <!-- 角色 -->
          <span v-else slot-scope="{ node, data }" class="tree-item-content item-item">
            <i class="icon iconfont-system jueseguanli"></i>
            <span :title="data[treeprops.label]" class="label">{{ data[treeprops.label] }}</span>
            <!-- 弹出菜单 -->
            <el-dropdown v-if="data.roleType && data.roleType !== '0'" trigger="click" @command="handleCommand($event, data)">
              <i class="icon iconfont-system gengduo"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="add-role"><i class="icon iconfont-system xinzeng" /> 新增子角色</el-dropdown-item>
                <el-dropdown-item command="edit-role"><i class="icon iconfont-system xiugai" /> 编辑角色 </el-dropdown-item>
                <el-dropdown-item command="del-role"><i class="icon iconfont-system shanchu" /> 删除角色</el-dropdown-item>
                <el-dropdown-item command="role-menu"><i class="icon iconfont-system quanxian" /> 权限分配</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
        </el-tree>
      </div>
      <div class="right-container">
        <div class="tab-bar-container">
          <el-tabs v-model="activeName">
            <el-tab-pane label="角色用户" name="角色用户"> </el-tab-pane>
            <el-tab-pane label="角色权限" name="角色权限"> </el-tab-pane>
          </el-tabs>
        </div>
        <div class="tab-content-container">
          <role-user v-show="activeName === '角色用户'" :role="currRole" />
          <role-menu v-show="activeName === '角色权限'" :role="currRole" :role-menus="roleMenus" @refreshSelect="getRoleMenus" />
        </div>
        <edit-role :visible="showEditDialog" :role="isAdd ? newRole : currRole" @closeDialog="closeDialog" />
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import searchBox from '@/views/components/search-box.vue'
import editRole from './edit-role.vue'
import roleUser from './role-user.vue'
import roleMenu from './role-menu.vue'
import { getRoleTree, deleteRole } from '@/api/system/role'
import { listByRole } from '@/api/system/role-menu'
export default {
  name: 'Role',
  components: {
    searchBox,
    editRole,
    roleUser,
    roleMenu
  },
  data() {
    return {
      // 角色
      roles: [],
      currRole: {},
      roleMenus: [],
      treeprops: {
        id: 'id',
        label: 'roleName',
        children: 'children',
        type: 'parentId',
        isLeaf: 'leaf'
      },
      // 编辑
      showEditDialog: false,
      isAdd: false,
      // 新增
      newRole: null,
      activeName: '角色用户'
    }
  },
  created() {
    this.getRoleTree()
  },
  methods: {
    getRoleTree() {
      this.loading = true
      getRoleTree().then(res => {
        if (res && res.status === 200) {
          this.loading = false
          this.roles = res.data
          // 默认选中第一个
          if (this.roles.length > 0) {
            this.currRole = this.roles[0]
          }
        }
      })
    },
    changeRole(role) {
      this.currRole = role
      this.getRoleMenus()
    },
    // 拿权限
    getRoleMenus() {
      const params = {
        roleId: this.currRole.id
      }
      listByRole(params).then((res) => {
        if (res && res.status === 200) {
          this.roleMenus = res.data.map((item) => {
            return item.id
          })
        }
      })
    },
    _filterNode(value, data) {
      if (!value) {
        return true
      }
      return data[this.treeprops.label].indexOf(value) !== -1
    },
    // tree下拉选择
    handleCommand(evt, entity) {
      if (evt === 'add-role') {
        this.addRole()
      } else if (evt === 'edit-role') {
        this.editRole()
      } else if (evt === 'del-role') {
        this.delRole()
      }
    },
    doSearchRole(searchKey) {
      this.$refs.eltree.filter(searchKey)
    },
    addRole() {
      this.isAdd = true
      this.newRole = {
        parentId: this.currRole.id,
        creator: store.state.global.userinfo.id
      }
      this.showEditDialog = true
    },
    editRole() {
      this.isAdd = false
      this.showEditDialog = true
    },
    delRole() {
      this.$confirm('删除后无法恢复, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        deleteRole({ id: this.currRole.id }).then(res => {
          if (res && res.status === 200) {
            this.getRoleTree()
            this.$message({ message: '删除成功', type: 'success' })
          } else {
            this.$message({ message: '删除失败', type: 'error' })
          }
        }).catch((err) => {
          console.error(err)
          this.$message({ message: '删除失败', type: 'error' })
        })
      }).catch(() => {
        // 取消
      })
    },
    closeDialog(refresh) {
      this.showEditDialog = false
      if (refresh) {
        this.getRoleTree()
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/styles/tree.scss';
  .content-container {
    .content {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: calc(100%);
      background: #fff;
      .left-container {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 300px;
        height: calc(100%);
        background: #fff;
        border-right: solid 1px #eee;
        // padding: 8px;
      }
      .right-container {
        display: flex;
        flex-direction: column;
        width: calc(100% - 300px);
        height: calc(100%);
      }
    }
  }
</style>
