<template>
  <el-dialog
    title="字典项"
    :visible.sync="visible"
    width="800px"
    :before-close="handleClose"
  >
    <el-form ref="addForm" label-width="90px" style="margin:0 auto;">
      <el-row>
        <el-col :span="12">
          <el-form-item label="字典代码：">
            <el-input v-model="itemModel.dictKey" size="small" auto-complete="false" placeholder="请输入字典代码" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="字典值：">
            <el-input v-model="itemModel.dictValue" size="small" auto-complete="false" placeholder="请输入字典值" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="字典类型：">
            <zxl-select-tree
              :multiple="false"
              :obj="typeProps"
              :tree-data="types"
              :cur-value="itemModel.typeCode"
              style="width: 100%;"
              @getValue="setType"
            ></zxl-select-tree>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="排序：">
            <el-input-number v-model="itemModel.sort" size="small" type="number" placeholder="请输入排序" style="width: 100%;" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="备注：">
            <el-form-item label="">
              <el-input
                v-model="itemModel.remark" size="small"
                type="textarea"
                resize="none"
                :autosize="{ minRows: 4, maxRows: 4 }"
                placeholder="请输入备注"
              />
            </el-form-item>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="状态：">
            <el-switch v-model="itemModel.status" size="small" :active-value="1" :inactive-value="0" active-color="#409EFF" inactive-color="#F56C6C" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose()">取 消</el-button>
      <el-button size="small" type="primary" @click="doSave()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { treeDictTypes, saveDictItem } from '@/api/system/dict'
import zxlSelectTree from '@/components/common/zxl-select-tree.vue'
export default {
  components: {
    zxlSelectTree
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    dictItem: {
      type: Object,
      default: () => {
        return { id: null }
      }
    }
  },
  data() {
    return {
      itemModel: {},
      types: [],
      typeProps: {
        id: 'typeCode',
        label: 'typeName',
        children: 'children'
      }
    }
  },
  watch: {
    dictItem(newVal) {
      this.itemModel = newVal
    }
  },
  mounted() {
    // 获取角色树
    this.getTypeTree()
  },
  methods: {
    getTypeTree() {
      treeDictTypes().then(res => {
        if (res && res.status === 200) {
          this.types = res.data
        }
      })
    },
    setType(dataKeys, datas) {
      this.itemModel.typeCode = dataKeys
    },
    doSave() {
      saveDictItem(this.itemModel).then(res => {
        this.$message.success('保存成功！')
        this.handleClose(true)
      }).catch((err) => {
        console.log(err)
        this.$message.error('保存失败！')
      })
    },
    handleClose(refresh) {
      this.$emit('closeDialog', refresh)
    }
  }
}
</script>
<style scoped lang="scss">
  ::v-deep .el-divider{
     margin: 8px 0;
     background: 0 0;
     border-top: 1px dashed #e8eaec;
     font-size: 14px;
  }
</style>
