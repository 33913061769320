<template>
  <el-dialog
    :visible.sync="uploadProgessShow"
    :before-close="handleClose"
    :close-on-click-modal="false"
    title="传输任务"
    width="800px"
    top="20vh"
    class="add-datasource-popup"
  >
    <div class="attachWrap">
      <el-tabs v-model="activeName">
        <el-tab-pane name="first">
          <span slot="label"> 正在上传 ({{ percentageList.length }})</span>
          <el-table
            :data="percentageList"
            stripe
            style="width: 100%;max-height:700px;overflow-y:auto"
          >
            <el-table-column
              prop="name"
              label="文件名"
              width="300"
            >
              <template slot-scope="scope">
                <div class="fileNameRow">
                  <div class="leftRow">
                    <span>
                      <!-- <svg-icon :icon-class="getProgessType(scope.row)"
                                style="margin: 0 5px;" /> -->
                    </span>
                  </div>
                  <div class="RigthRow">
                    <span :title="scope.row.name">{{ scope.row.name }}</span>
                    <span>{{ scope.row.nowSize }}MB / {{ scope.row.allSize }}MB</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="percentage"
              label="状态"
              width="180"
              align="left"
            >
              <template slot-scope="scope">
                <span>
                  <el-progress :percentage="scope.row.percentage" />
                </span>
                <span v-if="scope.row.percentage === 100">上传完成</span>
                <span v-else>{{ scope.row.isStop ? '暂停中' : '上传中' }}</span>
                <span>{{ scope.row.percentage }}%</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="caozuo"
              label="操作"
              align="center"
            >
              --
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <div style="padding-top:10px;font-size: 14px;color: #9EA8A9;">
        <i
          class="el-icon-warning-outline"
          style="color:rgb(255, 180, 59)"
        />
        温馨提示：文件上传过程中请勿刷新网页。若不慎刷新网页，需重新选择文件，再启动上传任务。
      </div>
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        v-if="confirmVisible"
        type="primary"
        @click="closeDialog"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
// import { getSlicesUploadInfo, slicesUploadTemporaryFile } from '@/api/business/material.js'

export default {
  components: {
  },
  props: {
    uploadProgessShow: {
      type: Boolean,
      default: false
    },
    percentageList: {
      type: Array,
      default: () => {
        return []
      }
    },
    prjData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    folderId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      activeName: 'first',
      mode: 'start',
      fileData: {},
      confirmVisible: false
    }
  },

  watch: {
    uploadProgessShow(newVal) {
      if (newVal) {
        const percentage = this.percentageList[0]
        const file = percentage.file
        const keyIndex = percentage.tmpFileKey
        this.getUploadInfo({ fileName: file.name, fileSize: file.size, tmpFileKey: keyIndex, hasReload: false }, file)
      }
    }
  },
  created() {
  },
  methods: {
    handleClose() {
      this.uploadProgessShow = false
    },
    getProgessType(item) {
      if (item.name === undefined) {
        return 'ns-file-unknow'
      }
      const type = item.name.split('.')
      switch (type[type.length - 1]) {
        case 'jpg':
          return 'jpg'
        case 'png':
          return 'jpg'
        case 'gif':
          return 'jpg'
        case 'dwg':
          return 'dwg'
        case 'docx':
          return 'ns-file-word'
        case 'doc':
          return 'ns-file-word'
        case 'pdf':
          return 'pdf'
        case 'xlsx':
          return 'ns-file-excel'
        case 'txt':
          return 'ns-file-txt'
        default:
          return 'ns-file-unknow'
      }
    },
    // 设置分片大小，默认20份
    setSize(size) {
      let sizeNum = 0
      if (size < 1024 * 1024 * 10) {
        sizeNum = size
      } else if (size > 1024 * 1024 * 10 && size < 1024 * 1024 * 100) {
        sizeNum = parseInt(size / 10)
      } else {
        sizeNum = parseInt(size / 20)
      }
      return sizeNum
    },
    getUploadInfo(data, file) {
      // getSlicesUploadInfo(data).then(res => {
      //   if (res.data && res.data.fileSize && res.data.fileSize > 0) {
      //     this.doUpload(res.data.fileSize, data, file)
      //   } else {
      //     this.doUpload(0, data, file)
      //   }
      // })
    },
    async doUpload(start, data, file) {
      if (start >= data.fileSize) {
        return
      } // 获取文件块的终止字节

      const chunkSize = this.setSize(data.fileSize)
      const fs = new FormData()
      const end = start + chunkSize > data.fileSize ? data.fileSize : start + chunkSize
      fs.append('fileName', data.fileName) // POST表单数据
      fs.append('file', file.raw.slice(start, end))
      fs.append('tmpFileKey', data.tmpFileKey)
      fs.append('projectId', this.prjData.id)
      fs.append('parentId', this.folderId)
      if (end === data.fileSize) {
        fs.append('finish', true)
      } else {
        fs.append('finish', false)
      }
      // if (this.mode === 'start') {
      //   fs.append('pId', this.fileData.id)
      //   slicesUploadTemporaryFile(fs).then(async d => {
      //     this.uploadFinish(data, file, d, 'start')
      //   })
      // } else {
      //   fs.append('pId', data.hasReload ? data.pdId : this.fileData.id)
      //   fs.append('dirId', data.hasReload ? data.pdId : this.fileData.id)
      //   fs.append('procInstId', this.attrs.bpmTask.procInstId || this.attrs.param.procInstId)
      //   fs.append('nodeId', this.attrs.bpmTask.taskDefKey || this.attrs.forms[0].nodeId)
      //   slicesUploadTemporaryFile(fs).then(async d => {
      //     this.uploadFinish(data, file, d, 'end')
      //   })
      // }
    },
    uploadFinish(data, file, d, typeName) {
      const fileCommon = d.data[0].size ? d.data[0].size : d.data[0].fileSize
      this.percentageList.forEach(res => {
        if (res.name === file.name) {
          const percentage = (fileCommon / data.fileSize) * 100
          this.$set(res, 'percentage', parseInt(percentage))
          this.$set(res, 'nowSize', (fileCommon / (1024 * 1024)).toFixed(2))
          if (this.fileData.id !== undefined) {
            this.$set(res, 'pId', parseInt(this.fileData.id))
          }
        }
      })
      localStorage.setItem('percentageList', JSON.stringify(this.percentageList))
      if (fileCommon !== data.fileSize) {
        this.getUploadInfo(data, file)
      } else {
        this.$notify({
          type: 'success',
          title: '提示',
          message: `文件${data.fileName}上传完成`,
          duration: 2000
        })
        if (d.status !== 200) {
          this.$message.error('上传文件失败')
        } else {
          this.confirmVisible = true
        }
      }
    },
    closeDialog() {
      this.$emit('closeDialog')
    }
  }
}
</script>
<style scoped lang="scss">
</style>
