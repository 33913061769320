<template>
  <el-dialog
    title="图标选择"
    width="1000px"
    style="z-index: 500;"
    :visible.sync="visible"
    :before-close="handleClose"
    append-to-body
  >
    <el-tabs v-model="iconName">
      <el-tab-pane v-for="iconCategory in exportIconList" :key="iconCategory.name" :label="iconCategory.description" :name="iconCategory.name">
        <div class="icons-container">
          <div v-for="(item, index) in iconCategory.glyphs" :key="index" :class="['icon-item', currIcon.name === item.name ? 'active' : '']" @click="selectIcon(item, iconCategory)">
            <i :class="`${iconCategory.font_family} ${iconCategory.css_prefix_text}${item.font_class}`" />
            <span class="icon-text"> {{ item.name }}</span>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose()">取 消</el-button>
      <el-button type="primary" @click="confirm()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { exportIconList } from '@/assets/iconfont/exportIcon'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      icons: [],
      fontFamily: '',
      cssPrefixText: '',
      currIcon: {},
      currIconText: '',
      iconName: 'system',
      exportIconList: []
    }
  },
  created() {
    this.exportIconList = exportIconList
    console.log('exportIconList', exportIconList)
  },
  methods: {
    handleClose() {
      this.$emit('closeDialog')
    },
    selectIcon(icon, iconCategory) {
      this.currIcon = icon
      this.currIconText = `${iconCategory.font_family} ${iconCategory.css_prefix_text}${icon.font_class}`
    },
    confirm() {
      this.$emit('confirm', this.currIconText)
      this.$emit('closeDialog')
    }
  }
}
</script>
  <style scoped lang="scss">
    .icons-container {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      height: 500px;
      overflow: scroll;
      .icon-item {
        display: flex;
        flex-direction: column;
        text-align: center;
        cursor: pointer;
        border: dotted 1px #eee;
        border-radius: 5px;
        width: 100px;
        height: 100px;
        line-height: 100px;
        margin: 0 5px 5px 0;
        i {
          width: 100%;
          height: 20px;
        }
        span {
          flex: 1;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
        }
        &.active,
        &:hover {
          background: #eee;
        }
      }
    }
  </style>

