<template>
  <div class="user-box">
    <div class="user-box-span">
      <i class="icon iconfont-system yonghuguanli" />
      <label class="username">{{ username }}</label>
    </div>
    <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
      <!-- <div class="avatar-wrapper">
                <img src="@/assets/images/user.png" class="user-avatar" />
                <i class="el-icon-caret-bottom" />
            </div> -->
      <i class="icon iconfont-system xiangxia" />
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>
          <span style="display:block;" @click="changePwd">修改用户信息</span>
        </el-dropdown-item>
        <el-dropdown-item divided>
          <span style="display:block;" @click="logout">登出</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { removeToken } from '@/utils/auth'
import { mapState } from 'vuex'
import store from '@/store'
export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.global.userinfo
    }),
    username() {
      if (store.state.global.userinfo) {
        return store.state.global.userinfo.name
      } else {
        return ''
      }
    }
  },
  methods: {
    changePwd() {
      this.$router.push({
        path: '/changePwd'
      })
    },
    logout() {
      removeToken()
      this.$router.push('/login')
    }
  }
}
</script>
<style lang="scss" scoped>
  .user-box {
    width: 140px;
    height: 60px;
    line-height: 60px;
    display: flex;
    color: #fff;
    flex-direction: row;
    .user-box-span {
      // width: 120px;
      font-size: 16px;
      cursor: pointer;
      color: #fff;
      .icon {
        font-size: 16px;
        margin-right: 5px;
      }
      .username {
        font-family: 微软雅黑;
      }
    }

    .right-menu-item {
      display: inline-block;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;
      .icon {
        font-size: 16px;
        margin-left: 5px;
        color: #fff;
      }
      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
</style>
