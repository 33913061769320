<template>
  <div class="tab-bar">
    <div v-for="tab in tabs" :key="tab.id" :class="'tab-bar-item ' + (tab.id === activeTab.id ? 'active' : '')" @click="toggleTab(tab)">
      <span class="tab-title">{{ tab.name }}</span>
      <i v-if="tab.id !== tabs[0].id" class="icon iconfont-system cuohao" @click.stop="closeTab(tab)" />
    </div>
  </div>
</template>

<script>
import store from '@/store'
export default {
  props: {
    defaultTab: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      tabs: [
        this.defaultTab
      ],
      activeTab: this.defaultTab
    }
  },
  computed: {
    currTab() {
      return store.state.global.tab
    },
    closedTab() {
      return store.state.global.closedtab
    }
  },
  watch: {
    currTab(newVal, oldVal) {
      if (!oldVal && newVal || newVal.id !== oldVal.id) { // tab发生了切换
        const tab = newVal
        let hasTab = false
        this.tabs.forEach(item => {
          if (item.id === tab.id) {
            hasTab = true
          }
        })
        if (!hasTab) {
          this.tabs.push(tab)
        }
        this.activeTab = tab
      }
    },
    closedTab(newVal) {
      if (newVal) {
        this.closeTab(newVal)
      }
    }
  },
  methods: {
    closeTab(tab) {
      this.tabs.splice(this.tabs.indexOf(tab), 1)
      this.activeTab = this.tabs[this.tabs.length - 1]
      store.commit('setTab', this.activeTab)
      this.$emit('closeTab', tab)
    },
    toggleTab(menu) {
      store.commit('setTab', menu)
    }
  }
}
</script>
<style scoped lang="scss">
  .tab-bar {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 40px;
    line-height: 40px;
    background: #fff;
    padding-left: 10px;
    margin-bottom: 5px;
    .tab-bar-item {
      min-width: 100px;
      height: 30px;
      margin: 4px 1px 0 4px;
      padding: 0 5px 0 10px;
      line-height: 30px;
      text-align: center;
      background: #eee;
      border: solid 1px #eee;
      border-radius: 2px;
      color: #000;
      cursor: pointer;
      &:hover {
        background: rgb(0, 166, 214);
        border: solid 1px rgb(0, 166, 214);
        color: #fff;
      }
      .tab-title {
        margin-right: 2px;
        font-size: 14px;
      }
      .icon {
        float: right;
        font-size: 12px;
        margin-right: 5px;
        color: #000;
        &:hover {
          color: #ff0000;
        }
      }
    }
    .active {
      background: rgb(0, 166, 214);
      border: solid 1px rgb(0, 166, 214);
      color: #fff;
    }
  }
</style>
