<template>
  <div class="content-container">
    <div class="content">
      <div class="left-container">
        <search-box width="290px" style="margin: 2px 5px 2px 5px;" @doSearch="doSearchOrg" />
        <el-tree
          ref="eltree"
          node-key="id"
          icon-class="custom"
          highlight-current
          class="data-tree"
          :data="orgs"
          :props="treeprops"
          :default-expand-all="true"
          :expand-on-click-node="false"
          :filter-node-method="_filterNode"
          @node-click="changeOrg"
        >
          <!-- 根目录 -->
          <span v-if="node.level === 1" slot-scope="{ node, data }" class="tree-item-content item-root">
            <i v-if="node.expanded" class="icon iconfont-system wenjianjiazhankai"></i>
            <i v-else class="icon iconfont-system wenjianjiaguanbi"></i>
            <span :title="data[treeprops.label]" class="label">{{ data[treeprops.label] }}</span>
            <!-- 弹出菜单 -->
            <el-dropdown trigger="click" @command="handleCommand($event, data)">
              <i class="icon iconfont-system gengduo"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="add-org"><i class="icon iconfont-system xinzeng" /> 新增机构</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
          <!-- 机构目录 -->
          <span v-else-if="data.children && data.children.length > 0" slot-scope="{ node, data }" class="tree-item-content item-folder">
            <i v-if="node.expanded" class="icon iconfont-system wenjianjiazhankai"></i>
            <i v-else class="icon iconfont-system wenjianjiaguanbi"></i>
            <span :title="data[treeprops.label]" class="label">{{ data[treeprops.label] }}</span>
            <!-- 弹出菜单 -->
            <el-dropdown trigger="click" @command="handleCommand($event, data)">
              <i class="icon iconfont-system gengduo"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="add-org"><i class="icon iconfont-system xinzeng" /> 新增子机构</el-dropdown-item>
                <el-dropdown-item command="edit-org"><i class="icon iconfont-system xiugai" /> 编辑机构 </el-dropdown-item>
                <el-dropdown-item command="del-org"><i class="icon iconfont-system shanchu" /> 删除机构</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
          <!-- 机构 -->
          <span v-else slot-scope="{ node, data }" class="tree-item-content item-item">
            <i class="icon iconfont-system jigouguanli"></i>
            <span :title="data[treeprops.label]" class="label">{{ data[treeprops.label] }}</span>
            <!-- 弹出菜单 -->
            <el-dropdown trigger="click" @command="handleCommand($event, data)">
              <i class="icon iconfont-system gengduo"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="add-org"><i class="icon iconfont-system xinzeng" /> 新增子机构</el-dropdown-item>
                <el-dropdown-item command="edit-org"><i class="icon iconfont-system xiugai" /> 编辑机构 </el-dropdown-item>
                <el-dropdown-item command="del-org"><i class="icon iconfont-system shanchu" /> 删除机构</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
        </el-tree>
      </div>
      <div class="right-container">
        <div class="toolbar-container">
          <search-box style="float: right; margin-right: 35px;" @doSearch="doSearchUser" />
        </div>
        <div class="table-container">
          <el-table
            ref="treeTable"
            border
            :row-key="'id'"
            :data="tableData"
            :row-class-name="tableRowClassName"
            v-bind="$attrs"
            size="small"
            highlight-current-row
            :header-cell-style="{ background: '#FAFAFA' }"
            class="table"
            @selection-change="handleSelectionChange"
            @select="rowSelect"
          >
            <el-table-column width="55" type="selection" reserve-selection align="center"></el-table-column>
            <el-table-column type="index" prop="id" label="序号" width="55" align="center"> </el-table-column>
            <el-table-column prop="username" label="用户名" align="center"> </el-table-column>
            <el-table-column prop="name" label="姓名" width="300" align="center"> </el-table-column>
            <el-table-column prop="createTime" label="创建时间" :formatter="$global.dateFormat" width="120" align="center"> </el-table-column>
            <el-table-column prop="status" label="状态" :formatter="statusFormat" width="100" align="center">
              <template slot-scope="scope">
                <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0" active-color="#409EFF" inactive-color="#F56C6C" disabled />
              </template>
            </el-table-column>
            <el-table-column width="140" label="操作" align="center">
              <template slot-scope="scope">
                <el-button type="text" size="mini" @click="delRelation(scope.row)">
                  <i class="icon iconfont-system shanchu"></i>
                  <span style="margin-left: 5px;">删除关联</span>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          class="page-container"
          :current-page="currPage"
          :page-size="pageSize"
          :total="total"
          layout="total, sizes, prev, pager, next"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
        <edit-org :visible="showEditDialog" :org="currOrg" @closeDialog="closeDialog" />
      </div>
    </div>
  </div>
</template>

<script>
import searchBox from '@/views/components/search-box.vue'
import editOrg from './edit-org.vue'
import { getOrgTree, getUsersByOrgId, deleteOrg, deleteUserOrg } from '@/api/system/org'
export default {
  name: 'Org',
  components: {
    searchBox,
    editOrg
  },
  data() {
    return {
      loading: false,
      tableData: [],
      currPage: 0,
      pageSize: 10,
      total: 0,
      showEditDialog: false,
      currOrg: {},
      treeprops: {
        id: 'id',
        label: 'orgName',
        children: 'children',
        type: 'parentId',
        isLeaf: 'leaf'
      },
      orgs: [],
      dataModelProps: {
        children: 'children',
        label: 'orgName'
      }
    }
  },
  watch: {
    currOrg(newVal) {
      this.getUsersByOrg(1, newVal)
    }
  },
  created() {
    this.getOrgTree()
  },
  methods: {
    getOrgTree() {
      getOrgTree().then(res => {
        if (res && res.status === 200) {
          this.orgs = res.data
          // 默认选中第一个
          if (this.orgs.length > 0) {
            this.currOrg = this.orgs[0]
          }
        }
      })
    },
    changeOrg(org) {
      this.currOrg = org
    },
    getUsersByOrg(pageIndex, org) {
      this.loading = true
      this.currPage = pageIndex
      const params = {
        orgId: org.id,
        pageIndex: pageIndex,
        pageSize: this.pageSize,
        key: this.searchKeyUser
      }
      getUsersByOrgId(params).then(res => {
        if (res && res.status === 200) {
          this.tableData = res.data.records
          this.total = res.data.total
        }
        this.loading = false
      }).catch(err => {
        console.error(err)
        this.loading = false
      })
    },
    handleCurrentChange(pageIndex) {
      this.getUsersByOrg(pageIndex, this.currOrg)
    },
    handleSizeChange(size) {
      this.pageSize = size
      this.getUsersByOrg(1, this.currOrg)
    },
    statusFormat(row) {
      if (row && row.status) {
        if (row.status === 1) {
          return '启用'
        }
      } else {
        return '禁用'
      }
    },
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 === 0) {
        return 'odd'
      }
      return ''
    },
    handleSelectionChange() {

    },
    rowSelect() {

    },
    _filterNode(value, data) {
      if (!value) {
        return true
      }
      return data[this.treeprops.label].indexOf(value) !== -1
    },
    // tree下拉选择
    handleCommand(evt, entity) {
      if (evt === 'add-org') {
        this.doAdd(entity)
      } else if (evt === 'edit-org') {
        this.doEdit(entity)
      } else if (evt === 'del-org') {
        this.delOrg(entity)
      }
    },
    doSearchOrg(searchKey) {
      this.$refs.eltree.filter(searchKey)
    },
    doSearchUser(searchKey) {

    },
    doAdd(entity) {
      this.currOrg = {
        parentId: entity.id
      }
      this.showEditDialog = true
    },
    doEdit(org) {
      this.currOrg = org
      this.showEditDialog = true
    },
    delOrg(org) {
      this.$confirm('删除后无法恢复, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        this.doDelOrg(org)
      })
    },
    doDelOrg(org) {
      deleteOrg({ id: org.id }).then(res => {
        if (res && res.status === 200) {
          this.getOrgTree()
          this.$message({ message: '删除成功', type: 'success' })
        } else {
          this.$message({ message: '删除失败', type: 'error' })
        }
      }).catch(err => {
        console.error(err)
        this.$message({ message: '删除失败', type: 'error' })
      })
    },
    delRelation(user) {
      const orgId = this.currOrg.id
      const userId = user.id
      if (orgId && userId) {
        this.$confirm('删除后无法恢复, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          this.doDelUserOrg(orgId, userId)
        })
      }
    },
    doDelUserOrg(orgId, userId) {
      deleteUserOrg({ orgId: orgId, userId: userId }).then(res => {
        if (res && res.status === 200) {
          this.getUsersByOrg(1, this.currOrg)
          this.$message({ message: '删除成功', type: 'success' })
        }
      })
    },
    closeDialog() {
      this.getOrgTree()
      this.showEditDialog = false
    }
  }
}
</script>
<style scoped lang="scss">
  @import '@/styles/tree.scss';
  .content-container {
    .content {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: calc(100%);
      background: #fff;
      .left-container {
        display: flex;
        flex-direction: column;
        width: 300px;
        height: calc(100%);
        background: #fff;
        border-right: solid 1px #eee;
        .data-tree {
          margin-left: 5px;
          width: calc(100% - 10px);
          .tree-item-content{
            width: calc(100% - 30px);
            font-size: 14px;
            display: flex;
            position: relative;
            span{
              flex: 1;
              color:rgba(96,98,102,1);
              line-height: 14px;
              .icon{
                font-size: 18px;
              }
            }
            &.item-root{
              .icon{
                color:rgba(255,190,22,1);
              }
              .icon-gengduo {
                width: 18px;
                color: #ddd;
              }
            }
            &.item-folder{
              .icon{
                color: rgba(255,190,22,1);
              }
              .icon-gengduo {
                width: 18px;
                color: #ddd;
              }
            }
            &.item-item{
              .icon{
                color: rgba(99,178,205,1);
              }
              .icon-gengduo {
                width: 18px;
                color: #ddd;
              }
            }
            .label{
              margin: 0px 6px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
      .right-container {
        display: flex;
        flex-direction: column;
        width: calc(100% - 300px);
        height: calc(100%);
      }
    }
  }
</style>
