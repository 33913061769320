<template>
  <el-dialog
    title="模块/菜单基本信息"
    :visible.sync="visible"
    width="800px"
    :before-close="handleClose"
  >
    <el-form ref="addForm" label-width="90px" style="margin:0 auto;">
      <el-row>
        <el-col :span="12">
          <el-form-item label="菜单名称：">
            <el-input v-model="menuModel.menuName" size="small" auto-complete="false" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="上级菜单：">
            <!-- <el-input v-model="menuModel.parentId" /> -->
            <zxl-select-tree
              :multiple="false"
              :obj="menuProps"
              :tree-data="menuTree"
              :cur-value="menuModel.parentId"
              @getValue="setMenu"
            ></zxl-select-tree>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="路由地址：">
            <el-input v-model="menuModel.path" size="small" auto-complete="false" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="页面：">
            <el-input v-model="menuModel.page" size="small" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="菜单排序：">
            <el-input-number v-model="menuModel.sort" size="small" style="width: 100%;" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="菜单图标：">
            <el-input v-model="menuModel.icon" size="small">
              <el-button slot="append" :icon="menuModel.icon" size="small" @click="showIconDialog" />
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="菜单类型：">
            <!-- <el-input v-model="menuModel.category" /> -->
            <el-select v-model="menuModel.category" size="small" style="width: 100%;">
              <el-option :value="0" label="模块">模块</el-option>
              <el-option :value="1" label="菜单">菜单</el-option>
              <el-option :value="2" label="按钮">按钮</el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="菜单状态：">
            <el-switch v-model="menuModel.status" size="small" :active-value="1" :inactive-value="0" active-color="#409EFF" inactive-color="#F56C6C" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="备注：">
            <el-input v-model="menuModel.remark" size="small" :rows="5" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose()">取 消</el-button>
      <el-button size="small" type="primary" @click="doSave()">确 定</el-button>
    </span>
    <icon-chooser :visible="iconDialogVisible" @closeDialog="closeIconDialog" @confirm="setIcon" />
  </el-dialog>
</template>

<script>
import { tree, save } from '@/api/system/menu'
import zxlSelectTree from '@/components/common/zxl-select-tree.vue'
import iconChooser from '@/components/icon-chooser'
export default {
  components: {
    zxlSelectTree,
    iconChooser
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    menu: {
      type: Object,
      default: () => {
        return { id: null }
      }
    },
    app: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      iconDialogVisible: false,
      menuModel: {},
      menuTree: {},
      menuProps: {
        id: 'id',
        label: 'menuName',
        children: 'children'
      }
    }
  },
  watch: {
    menu(newVal) {
      this.getMenuTree()
      this.menuModel = newVal
    }
  },
  methods: {
    showIconDialog() {
      this.iconDialogVisible = true
    },
    closeIconDialog() {
      this.iconDialogVisible = false
    },
    setIcon(icon) {
      this.menuModel.icon = icon
    },
    getMenuTree() {
      const params = {
        appId: this.app.id
      }
      tree(params).then(res => {
        if (res && res.status === 200) {
          this.menuTree = res.data
          this.menuTree.unshift(
            {
              id: '-1',
              menuName: '--请选择--',
              children: null
            }
          )
        }
      })
    },
    setMenu(dataKeys, datas) {
      if (dataKeys) {
        this.menuModel.parentId = dataKeys
      } else {
        this.menuModel.parentId = null
      }
    },
    handleClose() {
      this.$emit('closeDialog')
    },
    doSave() {
      this.menuModel.children = []
      save(this.menuModel).then(res => {
        this.$message.success('保存成功！')
        this.$emit('closeDialog', true)
      }).catch((err) => {
        console.log(err)
        this.$message.error('保存失败！')
      })
    }
  }
}
</script>
<style scoped lang="scss">
</style>
