import request from '@/utils/request'
const Qs = require('qs')

// 所有
export function all(data) {
  return request({
    url: '/school-bus/rest/student/all',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 分页
export function page(data) {
  return request({
    url: '/school-bus/rest/student/page',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 保存
export function save(data) {
  return request({
    url: '/school-bus/rest/student/save',
    method: 'post',
    data: Qs.stringify(data)
  })
}
// 删除
export function del(data) {
  return request({
    url: '/school-bus/rest/student/delete',
    method: 'post',
    data: Qs.stringify(data)
  })
}
// 批量上传
export function upload(data) {
  return request({
    url: '/school-bus/rest/student/upload',
    method: 'post',
    data: data
  })
}
