<template>
  <div class="content-container">
    <div class="content">
      <div class="left-container">
        <search-box width="290px" style="margin: 2px 5px 2px 5px;" @doSearch="doSearchApp" />
        <el-tree
          ref="eltree"
          node-key="id"
          icon-class="custom"
          highlight-current
          class="data-tree"
          :data="apps"
          :props="treeprops"
          :default-expand-all="true"
          :expand-on-click-node="false"
          @node-click="changeApp"
        >
          <!-- 根目录 -->
          <span v-if="node.level === 1" slot-scope="{ node, data }" class="tree-item-content item-root">
            <i v-if="node.expanded" class="icon iconfont-system wenjianjiazhankai"></i>
            <i v-else class="icon iconfont-system wenjianjiaguanbi"></i>
            <span :title="data[treeprops.label]" class="label">{{ data[treeprops.label] }}</span>
          </span>
          <!-- 应用 -->
          <span v-else slot-scope="{ node, data }" class="tree-item-content item-app">
            <i class="icon iconfont-system zixitong"></i>
            <span :title="data[treeprops.label]" class="label">{{ data[treeprops.label] }}</span>
          </span>
        </el-tree>
      </div>
      <div class="right-container">
        <div class="toolbar-container">
          <!-- <search-box style="float: right; margin-right: 35px;" @doSearch="doSearchMenu" /> -->
          <el-button type="primary" size="mini" @click="doAdd()">
            <i class="icon iconfont-system xinzeng"></i>
            <span>添加</span>
          </el-button>
        </div>
        <div class="table-container">
          <el-table
            ref="treeTable"
            v-loading="loading"
            border
            size="small"
            highlight-current-row
            :row-key="'id'"
            :data="menus"
            :row-class-name="tableRowClassName"
            :default-expand-all="true"
            :header-cell-style="{ background: '#FAFAFA' }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column width="55" type="selection" reserve-selection align="center"></el-table-column>
            <el-table-column type="index" prop="index" label="序号" width="55" align="center"> </el-table-column>
            <el-table-column prop="menuName" label="菜单名称" align="left"> </el-table-column>
            <el-table-column prop="path" label="路由地址" width="250" align="left"> </el-table-column>
            <el-table-column prop="page" label="页面" width="320" align="left"> </el-table-column>
            <el-table-column prop="sort" label="排序" width="50" align="center"> </el-table-column>
            <el-table-column prop="category" label="菜单类型" :formatter="categoryFormatter" width="80" align="center"> </el-table-column>
            <el-table-column prop="status" label="状态" width="100" :formatter="statusFormat" align="center">
              <template slot-scope="scope">
                <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0" active-color="#409EFF" inactive-color="#F56C6C" disabled />
              </template>
            </el-table-column>
            <el-table-column width="220" label="操作" align="center">
              <template slot-scope="scope">
                <el-button type="text" size="mini" @click="doEdit(scope.row)">
                  <i class="icon iconfont-system xiugai"></i>
                  <span style="margin-left: 5px;">编辑</span>
                </el-button>
                <el-button type="text" size="mini" @click="delMenu(scope.row)">
                  <i class="icon iconfont-system shanchu"></i>
                  <span style="margin-left: 5px;">删除</span>
                </el-button>
                <el-button type="text" size="mini" @click="doAdd(scope.row)">
                  <i class="icon iconfont-system xinzeng"></i>
                  <span style="margin-left: 5px;">新增子项</span>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <edit-menu :visible="showEditDialog" :menu="currMenu" :app="currApp" @closeDialog="closeDialog" />
      </div>
    </div>
  </div>
</template>

<script>
import searchBox from '@/views/components/search-box.vue'
import editMenu from './edit-menu.vue'
import { list as listApp } from '@/api/system/app'
import { list, tree, del } from '@/api/system/menu'
export default {
  name: 'Menu',
  components: {
    searchBox,
    editMenu
  },
  data() {
    return {
      showEditDialog: false,
      treeprops: {
        id: 'id',
        label: 'appName',
        children: 'children',
        type: 'parentId',
        isLeaf: 'leaf'
      },
      appSearchKey: '',
      currApp: {},
      apps: [],
      menuSearchKey: '',
      menus: [],
      currMenu: {},
      dataModelProps: {
        label: 'menuName',
        children: 'children'
      },
      loading: false
    }
  },
  created() {
    this.getApps()
  },
  methods: {
    doSearchApp(searchKey) {
      this.appSearchKey = searchKey
      this.getApps()
    },
    doSearchMenu(searchKey) {
      this.menuSearchKey = searchKey
      this.getMenuTree()
    },
    getApps() {
      const params = {
        key: this.appSearchKey
      }
      listApp(params).then((res) => {
        if (res && res.status === 200) {
          this.apps = [{
            id: '-1',
            appName: '应用列表',
            appCode: 'appList',
            children: res.data
          }]
          if (res.data && res.data.length > 0) {
            this.currApp = res.data[0]
            this.getMenuTree()
          }
        }
      })
    },
    changeApp(app) {
      this.currApp = app
      if (app.id) {
        this.getMenuTree()
      }
    },
    getMenuTree() {
      this.loading = true
      const params = {
        key: this.menuSearchKey,
        appId: this.currApp.id
      }
      tree(params).then(res => {
        if (res && res.status === 200) {
          this.menus = res.data
          // 默认选中第一个
          if (this.menus.length > 0) {
            this.currMenu = this.menus[0]
          }
        }
        this.loading = false
      }).catch((err) => {
        console.error(err)
        this.loading = false
      })
    },
    categoryFormatter(row) {
      switch (row['category']) {
        case 0:
          return '模块'
        case 1:
          return '菜单'
        case 2:
          return '按钮'
        default:
          return ''
      }
    },
    statusFormat(row) {
      if (row && row.status) {
        if (row.status === 1) {
          return '启用'
        }
      } else {
        return '禁用'
      }
    },
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 === 0) {
        return 'odd'
      }
      return ''
    },
    handleSelectionChange() {

    },
    doAdd(entity) {
      this.currMenu = {
        appId: this.currApp.id,
        status: 1,
        isDeleted: 0
      }
      console.log('entity', entity)
      if (entity) {
        this.currMenu.parentId = entity.id
      } else {
        this.currMenu.parentId = '-1'
      }
      this.showEditDialog = true
    },
    doEdit(entity) {
      this.currMenu = entity
      this.showEditDialog = true
    },
    delMenu(entity) {
      this.$confirm('删除后无法恢复, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        entity.children = []
        del(entity).then(res => {
          if (res && res.status === 200) {
            this.$message({ message: '删除成功', type: 'success' })
            this.getMenuTree()
          } else {
            this.$message({ message: '删除失败', type: 'error' })
          }
        }).catch((err) => {
          console.error(err)
          this.$message({ message: '删除失败', type: 'error' })
        })
      })
    },
    closeDialog(refresh) {
      this.showEditDialog = false
      if (refresh) {
        this.changeApp(this.currApp)
      }
    }
  }
}
</script>
<style scoped lang="scss">
  @import '@/styles/tree.scss';
  .content-container {
    .content {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: calc(100%);
      background: #fff;
      .left-container {
        display: flex;
        flex-direction: column;
        width: 300px;
        height: calc(100%);
        background: #fff;
        border-right: solid 1px #eee;
        .data-tree {
          margin-left: 5px;
          width: calc(100% - 10px);
          .tree-item-content{
            width: calc(100% - 30px);
            font-size: 14px;
            display: flex;
            position: relative;
            span{
              flex: 1;
              text-align: left;
              color:rgba(96,98,102,1);
              line-height: 14px;
            }
            &.item-root{
              .icon{
                color:rgba(255,190,22,1);
                font-size: 16px;
              }
            }
            &.item-app{
              .icon{
                color: rgba(99,178,205,1);
                font-size: 14px;
              }
            }
            .label{
              margin: 0px 6px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
      .right-container {
        display: flex;
        flex-direction: column;
        width: calc(100% - 300px);
        height: calc(100% - 66px);
        .table-container {
          flex: 1;
          overflow: scroll;
        }
      }
    }
  }
</style>
