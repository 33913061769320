<template>
  <div class="content-container">
    <el-form>
      <el-form-item label="手机号">
        <el-input v-model="tel" />
      </el-form-item>
      <el-form-item label="内容">
        <el-input v-model="content" type="textarea" />
      </el-form-item>
      <el-form-item>
        <el-button @click="sendMsg">发短信</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="getMsg">接短信</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { sendSms, getSms } from '@/utils/sms'
export default {
  data() {
    return {
      tel: '19938803025',
      content: '这是测试内容'
    }
  },
  methods: {
    sendMsg() {
      sendSms(this.tel, this.content).then((res) => {
        if (res && res.success) {
          this.$message.success('发送成功！')
        } else {
          this.$message.error('发送失败！')
        }
      })
    },
    getMsg() {
      getSms().then((res) => {
        if (res) {
          this.$message.success('接收成功！内容为' + JSON.stringify(res))
        } else {
          this.$message.error('接收失败！')
        }
      })
    }
  }
}
</script>
<style scoped>
  .content-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 500px;
  }
</style>
