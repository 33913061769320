/**
 * checkedlist 传入的参数
 */
const arelist = [143866, 1622335719513006000, 1622335736550269000, 35407, 143842, 1622335212820111400, 1622335232642392000]
export function checkmodel(checkedlist) {
  for (let i = 0; i < checkedlist.length; i++) {
    if (arelist.includes(checkedlist[i])) {
      return true
    }
  }
}
