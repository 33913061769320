<template>
  <div class="oa-header">
    <Logo class="oa-header-logo" />
    <sub-system class="oa-header-sub-system" :app-name="appName" />
    <UserBox class="oa-header-user-box" />
  </div>
</template>

<script>
import UserBox from './user-box'
import SubSystem from './sub-system'
import Logo from './logo'
export default {
  components: {
    UserBox, Logo, SubSystem
  },
  props: {
    appName: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="scss" scoped>
  .oa-header {
    position: relative;
    width: 100%;
    height: 60px;
    // background: #66b1ff; //linear-gradient(226deg, #87CEEB 0%, #708CB8 100%);
    background: url('~@/assets/images/header.jpg') no-repeat ;
    background-color: rgb(0, 166, 214);
    display: flex;
    flex-direction: row;
    .oa-header-logo {
        left: 20px;
    }
    .oa-header-sub-system {
        flex: 1;
    }
    .oa-header-user-box {
        right: 0;
    }
  }
</style>
