<template>
  <div class="content-container">
    <div class="content">
      <div class="left-container">
        <search-box width="290px" style="margin: 2px 5px 2px 5px;" @doSearch="doSearchDictType" />
        <el-tree
          ref="eltree"
          node-key="id"
          icon-class="custom"
          highlight-current
          class="data-tree"
          :data="dictTypes"
          :props="treeprops"
          :default-expand-all="true"
          :expand-on-click-node="false"
          :filter-node-method="_filterNode"
          @node-collapse="showMenu=false"
          @node-click="changeDictType"
        >
          <!-- 根目录 -->
          <span v-if="node.level === 1" slot-scope="{ node, data }" class="tree-item-content item-root">
            <i v-if="node.expanded" class="icon iconfont-system wenjianjiazhankai"></i>
            <i v-else class="icon iconfont-system wenjianjiaguanbi"></i>
            <span :title="data[treeprops.label]" class="label">{{ data[treeprops.label] }}</span>
            <!-- 弹出菜单 -->
            <el-dropdown trigger="click" @command="handleCommand($event, data)">
              <i class="icon iconfont-system gengduo"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="add-dict-type"><i class="icon iconfont-system xinzeng" /> 新增子节点</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
          <!-- 节点目录 -->
          <span v-else-if="data.children && data.children.length > 0" slot-scope="{ node, data }" class="tree-item-content item-folder">
            <i v-if="node.expanded" class="icon iconfont-system wenjianjiazhankai"></i>
            <i v-else class="icon iconfont-system wenjianjiaguanbi"></i>
            <span :title="data[treeprops.label]" class="label">{{ data[treeprops.label] }}</span>
            <!-- 弹出菜单 -->
            <el-dropdown trigger="click" @command="handleCommand($event, data)">
              <i class="icon iconfont-system gengduo"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="add-dict-type"><i class="icon iconfont-system xinzeng" /> 新增子节点</el-dropdown-item>
                <el-dropdown-item command="edit-dict-type"><i class="icon iconfont-system xiugai" /> 编辑节点 </el-dropdown-item>
                <el-dropdown-item command="del-dict-type"><i class="icon iconfont-system shanchu" /> 删除节点</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
          <!-- 节点 -->
          <span v-else slot-scope="{ node, data }" class="tree-item-content item-item">
            <i class="icon iconfont-system shujuzidian"></i>
            <span :title="data[treeprops.label]" class="label">{{ data[treeprops.label] }}</span>
            <!-- 弹出菜单 -->
            <el-dropdown trigger="click" @command="handleCommand($event, data)">
              <i class="icon iconfont-system gengduo"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="add-dict-type"><i class="icon iconfont-system xinzeng" /> 新增子节点</el-dropdown-item>
                <el-dropdown-item command="edit-dict-type"><i class="icon iconfont-system xiugai" /> 编辑节点 </el-dropdown-item>
                <el-dropdown-item command="del-dict-type"><i class="icon iconfont-system shanchu" /> 删除节点</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
        </el-tree>
      </div>
      <div class="right-container">
        <div class="toolbar-container">
          <el-button type="primary" size="mini" style="float: left;" @click="addDictItem()">
            <i class="icon iconfont-system xinzeng" />
            <span>添加</span>
          </el-button>
          <!-- <el-button type="danger" size="small" style="float: left;" @click="batchDelDictItem()">
            <i class="icon iconfont-system shanchu" />
            <span>批量删除</span>
          </el-button> -->
          <search-box width="290px" style="margin: 2px 35px 2px 5px; float: right;" @doSearch="doSearchDictItem" />
        </div>
        <div class="table-container">
          <el-table
            ref="treeTable"
            border
            :row-key="'id'"
            :data="dictItems"
            :row-class-name="tableRowClassName"
            v-bind="$attrs"
            size="small"
            highlight-current-row
            :header-cell-style="{ background: '#FAFAFA' }"
            class="table"
            @selection-change="handleSelectionChange"
            @select="rowSelect"
          >
            <el-table-column width="55" type="selection" reserve-selection align="center"></el-table-column>
            <el-table-column type="index" prop="id" label="序号" width="55" align="center"> </el-table-column>
            <el-table-column prop="typeCode" label="类型代码" width="150" align="center"> </el-table-column>
            <el-table-column prop="dictKey" label="字典代码" width="150" align="center"> </el-table-column>
            <el-table-column prop="dictValue" label="字典值" width="150" align="center"> </el-table-column>
            <el-table-column prop="sort" label="排序" width="150" align="center"> </el-table-column>
            <el-table-column prop="status" label="状态" :formatter="statusFormat" width="150" align="center">
              <template slot-scope="scope">
                <el-switch v-model="scope.row.status" size="mini" :active-value="1" :inactive-value="0" active-color="#409EFF" inactive-color="#F56C6C" disabled />
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注" align="center"> </el-table-column>
            <el-table-column label="操作" align="center" width="150">
              <template slot-scope="scope">
                <el-button type="text" size="mini" @click="editDictItem(scope.row)">
                  <i class="icon iconfont-system xiugai"></i>
                  <span style="margin-left: 5px;">修改</span>
                </el-button>
                <el-button type="text" size="mini" @click="delDictItem(scope.row)">
                  <i class="icon iconfont-system shanchu"></i>
                  <span style="margin-left: 5px;">删除</span>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <edit-dict-type :visible="showEditTypeDialog" :dict-type="currDictType" @closeDialog="closeTypeDialog" />
        <edit-dict-item :visible="showEditItemDialog" :dict-item="currDictItem" @closeDialog="closeItemDialog" />
      </div>
    </div>
  </div>
</template>

<script>
import { treeDictTypes, listDictItemByType, delDictType, delDictItem, delDictItems } from '@/api/system/dict'
import searchBox from '@/views/components/search-box.vue'
import editDictType from './edit-dict-type.vue'
import editDictItem from './edit-dict-item.vue'
export default {
  name: 'Dictionary',
  components: {
    searchBox,
    editDictType,
    editDictItem
  },
  data() {
    return {
      dictItemSearchKey: '',
      currDictType: {},
      currDictItem: {},

      showEditTypeDialog: false,
      showEditItemDialog: false,

      dictTypes: [],
      dictItems: [],
      treeprops: {
        id: 'id',
        label: 'typeName',
        children: 'children',
        type: 'parentId'
      }
    }
  },
  created() {
    this.loadDictTypeTree()
  },
  methods: {
    loadDictTypeTree() {
      const params = {

      }
      treeDictTypes(params).then(res => {
        if (res && res.status === 200) {
          this.dictTypes = res.data
        }
      })
    },
    _filterNode(value, data) {
      if (!value) {
        return true
      }
      return data[this.treeprops.label].indexOf(value) !== -1
    },
    changeDictType(dictType) {
      this.currDictType = dictType
      this.getDictItemsByType()
    },
    getDictItemsByType() {
      const params = {
        typeCode: this.currDictType.typeCode,
        key: this.dictItemSearchKey
      }
      listDictItemByType(params).then(res => {
        if (res && res.status === 200) {
          this.dictItems = res.data
        }
      })
    },
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 === 0) {
        return 'odd'
      }
      return ''
    },
    statusFormat(row) {
      if (row && row.status) {
        if (row.status === 1) {
          return '启用'
        }
      } else {
        return '禁用'
      }
    },
    handleSelectionChange() {

    },
    rowSelect() {

    },
    // tree下拉选择
    handleCommand(evt, entity) {
      if (evt === 'add-dict-type') {
        this.addDictType()
      } else if (evt === 'edit-dict-type') {
        this.editDictType()
      } else if (evt === 'del-dict-type') {
        this.delDictType()
      }
    },
    doSearchDictType(searchKey) {
      this.$refs.eltree.filter(searchKey)
    },
    doSearchDictItem(searchKey) {
      this.dictItemSearchKey = searchKey
      this.getDictItemsByType()
    },
    addDictType() {
      this.currDictType = {
        parentId: this.currDictType.id,
        status: 1
      }
      this.showEditTypeDialog = true
    },
    addDictItem() {
      this.currDictItem = {
        typeCode: this.currDictType.typeCode,
        status: 1
      }
      this.showEditItemDialog = true
    },
    editDictType() {
      this.showEditTypeDialog = true
    },
    editDictItem(dictItem) {
      this.currDictItem = dictItem
      this.showEditItemDialog = true
    },
    // 删除
    delDictType() {
      this.$confirm('删除后无法恢复, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        this.currDictType.children = []
        delDictType(this.currDictType).then(res => {
          if (res && res.status === 200) {
            this.$message({ message: '删除成功', type: 'success' })
            this.loadDictTypeTree()
          } else {
            this.$message({ message: '删除失败', type: 'error' })
          }
        }).catch((err) => {
          console.error(err)
          this.$message({ message: '删除失败', type: 'error' })
        })
      })
    },
    delDictItem(entity) {
      this.$confirm('删除后无法恢复, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        entity.children = []
        delDictItem(entity).then(res => {
          if (res && res.status === 200) {
            this.$message({ message: '删除成功', type: 'success' })
            this.getDictItemsByType()
          } else {
            this.$message({ message: '删除失败', type: 'error' })
          }
        }).catch((err) => {
          console.error(err)
          this.$message({ message: '删除失败', type: 'error' })
        })
      })
    },
    closeTypeDialog(refresh) {
      this.showEditTypeDialog = false
      if (refresh) {
        this.loadDictTypeTree()
      }
    },
    closeItemDialog(refresh) {
      this.showEditItemDialog = false
      if (refresh) {
        this.getDictItemsByType()
      }
    }
  }
}
</script>
<style scoped lang="scss">
  @import '@/styles/tree.scss';
  .content-container {
    .content {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: calc(100%);
      background: #fff;
      .left-container {
        display: flex;
        flex-direction: column;
        width: 300px;
        height: calc(100%);
        background: #fff;
        border-right: solid 1px #eee;
        .data-tree {
          margin-left: 5px;
          width: calc(100% - 10px);
          .tree-item-content{
            width: calc(100% - 30px);
            font-size: 14px;
            display: flex;
            position: relative;
            span{
              flex: 1;
              color:rgba(96,98,102,1);
              line-height: 14px;
              .icon{
                font-size: 18px;
              }
            }
            &.item-root{
              .icon{
                color:rgba(255,190,22,1);
              }
              .icon-gengduo {
                width: 18px;
                color: #ddd;
              }
            }
            &.item-folder{
              .icon{
                color: rgba(255,190,22,1);
              }
              .icon-gengduo {
                width: 18px;
                color: #ddd;
              }
            }
            &.item-item{
              .icon{
                color: rgba(99,178,205,1);
              }
              .icon-gengduo {
                width: 18px;
                color: #ddd;
              }
            }
            .label{
              margin: 0px 6px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
      .right-container {
        display: flex;
        flex-direction: column;
        width: calc(100% - 300px);
        height: calc(100% - 66px);
      }
    }
  }
</style>
