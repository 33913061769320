import Vue from 'vue'
import App from './App.vue'

import router from './router/index'
import store from './store'
import './permission'
import '@/styles/index.scss'
// import '@/styles/tailwindcss.css'
// import '@/assets/iconfont/iconfont.css'
// import '@/assets/iconfont/iconfont.js'
import { setIconFontFunc } from '@/assets/iconfont'
setIconFontFunc()

// 全局JS
import * as global from './utils/global'
Vue.prototype.$global = global

import * as bindMap from './utils/bindMap'
Vue.prototype.$bindMap = bindMap
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

// 引入echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false
Vue.config.warnHandler = () => {}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
