import router from './router'
import store from './store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'
import { treeByUser } from '@/api/system/user-menu'

// 白名单
const whiteList = ['']
router.beforeEach(async(to, from, next) => {
  NProgress.start()
  if (!getToken()) {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      if (to.path !== '/login') {
        next({ path: '/login' })
        NProgress.done()
      } else {
        next()
      }
    }
  } else {
    // 有token
    if (to.path === '/login') {
      store.commit('setToken', '')
      store.commit('setUserInfo', null)

      next({ path: '/login' })
      NProgress.done() // if current page is dashboard will not trigger	afterEach hook, so manually handle it
    } else {
      if (!store.state.global.userinfo) {
        const userStr = localStorage.getItem('userInfo')
        if (userStr) {
          console.log(userStr)
          const userJson = JSON.parse(userStr)

          store.commit('setToken', userJson.id)
          store.commit('setUserInfo', userJson)
        }
        next({ ...to, replace: true })
      } else {
        // 初始化菜单
        if (!store.state.global.dynamicRoutes) {
          const params = {
            userId: store.state.global.userinfo.id
          }
          treeByUser(params).then((res) => {
            if (res && res.status === 200) {
              const routers = makeRoute(res.data)
              store.commit('setDynamicRoutes', routers)
              router.addRoutes(routers)
            }
          })
        }
        next()
      }
    }
    NProgress.done()
  }
})
router.onReady((to, from) => {
  // getMenus
})
router.afterEach(() => {
  NProgress.done()
})

function makeRoute(menus) {
  const routes = []
  if (menus && menus.length > 0) {
    menus.forEach((menu) => {
      routes.push({
        id: menu.id,
        path: menu.path,
        name: menu.menuName || menu.appName,
        icon: menu.icon,
        gategory: menu.category,
        component: getPath(menu),
        children: makeRoute(menu.children)
      })
    })
  }
  return routes
}

function getPath(item) {
  return require(`@/views${item.page}`).default
}
