import request from '@/utils/request'
const Qs = require('qs')

// 获取字典类型树
export function treeDictTypes(data) {
  return request({
    url: '/rumpson-system/rest/sysDict/treeDictTypes',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 根据字典类型ID查条目
export function listDictItemByType(data) {
  return request({
    url: '/rumpson-system/rest/sysDict/listDictItemByType',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 保存字典类型
export function saveDictType(data) {
  return request({
    url: '/rumpson-system/rest/sysDict/saveDictType',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 保存字典项
export function saveDictItem(data) {
  return request({
    url: '/rumpson-system/rest/sysDict/saveDictItem',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 删除字典类型
export function delDictType(data) {
  return request({
    url: '/rumpson-system/rest/sysDict/delDictType',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 删除字典项
export function delDictItem(data) {
  return request({
    url: '/rumpson-system/rest/sysDict/delDictItem',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 批量删除字典项
export function delDictItems(data) {
  return request({
    url: '/rumpson-system/rest/sysDict/delDictItems',
    method: 'post',
    data: Qs.stringify(data)
  })
}
