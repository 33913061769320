<template>
  <div class="sub-content-container">
    <div class="toolbar-container">
      <search-box style="float: right; margin-right: 35px;" @doSearch="doSearch" />
    </div>
    <div class="table-container">
      <el-table
        ref="treeTable"
        border
        :row-key="'id'"
        :data="tableData"
        :row-class-name="tableRowClassName"
        size="small"
        highlight-current-row
        :header-cell-style="{ background: '#FAFAFA' }"
        @selection-change="handleSelectionChange"
        @select="rowSelect"
      >
        <el-table-column width="55" type="selection" reserve-selection align="center"></el-table-column>
        <el-table-column type="index" prop="id" label="序号" width="55" align="center"> </el-table-column>
        <el-table-column prop="userName" label="用户名称" width="140" align="left"> </el-table-column>
        <el-table-column prop="account" label="登录账号" width="100" align="left"> </el-table-column>
        <el-table-column prop="serviceId" label="服务实例" width="120" align="left"> </el-table-column>
        <el-table-column prop="serverIp" label="服务IP" width="120" align="left"> </el-table-column>
        <el-table-column prop="env" label="环境" width="50" align="left"> </el-table-column>
        <el-table-column prop="title" label="操作方式" width="200" align="left"> </el-table-column>
        <el-table-column prop="method" label="请求方法" width="70" align="left"> </el-table-column>
        <el-table-column prop="requestUri" label="请求接口" align="left"> </el-table-column>
        <el-table-column prop="createTime" label="时间" width="140" align="left"> </el-table-column>
      </el-table>
    </div>
    <el-pagination
      class="page-container"
      :current-page="currPage"
      :page-size="pageSize"
      :total="total"
      size="mini"
      layout="total, sizes, prev, pager, next"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    >
    </el-pagination>
    <!-- <editparams :visible="showEditDialog" @closeDialog="closeDialog"/> -->
  </div>
</template>

<script>
import searchBox from '@/views/components/search-box.vue'
// import editparams from './editparams.vue'
import { listLogApi } from '@/api/system/log'
export default {
  name: 'Params',
  components: {
    searchBox
    // editparams
  },
  data() {
    return {
      tableData: [
        { id: 1, paramName: '系统名称', paramValue: '社会治理综合管理平台', remark: '' },
        { id: 2, paramName: '版权', paramValue: 'zxl<za59418@163.com>', remark: '' },
        { id: 3, paramName: '刷新频率', paramValue: '1次/10分钟', remark: '' }
      ],
      currPage: 0,
      pageSize: 10,
      total: 0,
      searchKey: '',
      showEditDialog: false
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      const params = {
        current: this.currPage,
        size: this.pageSize,
        title: this.searchKey
      }
      listLogApi(params).then(res => {
        if (res && res.status === 200) {
          this.tableData = res.data.records
          this.total = res.data.total
        }
      })
    },
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 === 0) {
        return 'odd'
      }
      return ''
    },
    handleSelectionChange() {

    },
    rowSelect() {

    },
    doSearch(searchKey) {
      this.searchKey = searchKey
      this.loadData()
    },
    handleCurrentChange(current) {
      this.currPage = current
      this.loadData()
    },
    handleSizeChange(size) {
      this.pageSize = size
      this.loadData()
    },
    closeDialog() {
      this.showEditDialog = false
    }
  }
}
</script>
<style scoped lang="scss">
</style>
