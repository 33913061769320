<template>
  <el-dialog
    title="用户基本信息"
    :visible.sync="visible"
    width="800px"
    :before-close="handleClose"
  >
    <el-form ref="addForm" label-width="90px" style="margin:0 auto;">
      <form-header>
        <i class="icon iconfont-system yonghuguanli"></i>&nbsp;
        <span>登录信息</span>
      </form-header>
      <el-row>
        <el-col :span="12">
          <el-form-item label="用户名：">
            <el-input v-model="userModel.username" size="small" auto-complete="false" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="密码：">
            <el-input v-model="userModel.password" size="small" type="password" auto-complete="false" />
          </el-form-item>
        </el-col>
      </el-row>
      <form-header>
        <i class="icon iconfont-system rizhiguanli"></i>&nbsp;
        <span>用户基本信息</span>
      </form-header>
      <el-row>
        <el-col :span="12">
          <el-form-item label="姓名：">
            <el-input v-model="userModel.name" size="small" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="电话：">
            <el-input v-model="userModel.tel" size="small" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="邮箱：">
            <el-input v-model="userModel.email" size="small" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="状态：">
            <el-switch v-model="userModel.status" size="small" :active-value="1" :inactive-value="0" active-color="#409EFF" inactive-color="#F56C6C" />
          </el-form-item>
        </el-col>
      </el-row>
      <form-header>
        <i class="icon iconfont-system jueseguanli"></i>&nbsp;
        <span>角色机构</span>
      </form-header>
      <el-row>
        <el-col :span="12">
          <el-form-item label="所属角色：">
            <zxl-select-tree
              :multiple="true"
              :obj="roleProps"
              :tree-data="roles"
              :cur-value="currRoles"
              @getValue="setRole"
            ></zxl-select-tree>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属机构：">
            <zxl-select-tree
              :multiple="false"
              :obj="orgProps"
              :tree-data="orgs"
              :cur-value="currOrg"
              @getValue="setOrg"
            ></zxl-select-tree>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose()">取 消</el-button>
      <el-button size="small" type="primary" @click="doSave()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import md5 from 'js-md5'
import { saveUser } from '@/api/system/user'
import { getRoleTree } from '@/api/system/role'
import { getOrgTree } from '@/api/system/org'
import zxlSelectTree from '@/components/common/zxl-select-tree.vue'
import FormHeader from '@/views/components/form-header.vue'
export default {
  components: {
    zxlSelectTree,
    FormHeader
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default: () => {
        return { id: null }
      }
    }
  },
  data() {
    return {
      userModel: {},
      orgs: {},
      roles: [],
      roleProps: {
        id: 'id',
        label: 'roleName',
        children: 'children'
      },
      orgProps: {
        id: 'id',
        label: 'orgName',
        children: 'children'
      },
      currRoles: null,
      currOrg: null
    }
  },
  watch: {
    user(newVal) {
      this.userModel = newVal
      this.currRoles = newVal.roles
      this.currOrg = newVal.org
    }
  },
  mounted() {
    // 获取角色树
    this.getRoleTree()
    this.getOrgTree()
  },
  methods: {
    getRoleTree() {
      getRoleTree().then(res => {
        if (res && res.status === 200) {
          this.roles = res.data
        }
      })
    },
    getOrgTree() {
      getOrgTree().then(res => {
        if (res && res.status === 200) {
          this.orgs = res.data
        }
      })
    },
    setRole(dataKeys, datas) {
      // console.log(dataKeys, datas)
      this.userModel.roles = dataKeys
    },
    setOrg(dataKeys, datas) {
      // console.log(dataKeys, datas)
      this.userModel.org = dataKeys
    },
    handleClose() {
      this.$emit('closeDialog')
    },
    doSave() {
      this.userModel.password = md5(this.userModel.password)
      saveUser(this.userModel).then(res => {
        this.$message.success('保存成功！')
        this.$emit('closeDialog', true)
      }).catch((err) => {
        console.log(err)
        this.$message.error('保存失败！')
      })
    }
  }
}
</script>
<style scoped lang="scss">
  ::v-deep .el-divider{
     margin: 8px 0;
     background: 0 0;
     border-top: 1px dashed #e8eaec;
     font-size: 14px;
  }
</style>
