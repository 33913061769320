<template>
  <div class="menu-box">
    <el-menu mode="vertical" class="menu" :default-openeds="defaultOpends">
      <template v-for="submenu in menus">
        <el-menu-item
          v-if="submenu.gategory === 2"
          :key="submenu.id"
          :class="['menu-item', (activeMenuId === submenu.id ? 'active' : '')]"
          @click="changeMenu(submenu)"
        >
          <i :class="['icon', submenu.icon]"></i>
          <span slot="title">{{ submenu.name }}</span>
        </el-menu-item>
        <el-submenu v-else :key="submenu.id" :index="submenu.id" class="sub-menu">
          <template slot="title">
            <i :class="['icon', submenu.icon]"></i>
            <span>{{ submenu.name }}</span>
          </template>
          <el-menu-item
            v-for="menu in submenu.children"
            :key="menu.id"
            :index="menu.id"
            :class="['menu-item', (activeMenuId === menu.id ? 'active' : '')]"
            @click="changeMenu(menu)"
          >
            <i :class="['icon', menu.icon]"></i>
            <span slot="title">{{ menu.name }}</span>
          </el-menu-item>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>
<script>
import store from '@/store'
export default {
  props: {
    menus: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      activeMenuId: ''
    }
  },
  computed: {
    currMenu() {
      return store.state.global.tab
    },
    defaultOpends() {
      if (this.menus.length > 0) {
        if (this.menus[0].children && this.menus[0].children.length > 0) {
          return [this.menus[0].id]
        } else if (this.menus[1].children && this.menus[1].children.length > 0) {
          return [this.menus[1].id]
        } else {
          return []
        }
      } else if (this.menus.length > 2) {
        return [this.menus[1].id]
      } else {
        return []
      }
    }
  },
  watch: {
    currMenu(newVal, oldVal) {
      if (!oldVal && newVal || newVal.id !== oldVal.id) { // tab发生了切换
        const menu = newVal
        this.activeMenuId = menu.id
        this.$router.push({ path: menu.path })
      }
    }
  },
  created() {
    // 默认选中第一项
    if (this.menus && this.menus.length > 0) {
      if (this.menus[0].children && this.menus[0].children.length > 0) {
        this.activeMenuId = this.menus[0].children[0].id
      } else {
        this.activeMenuId = this.menus[0].id
      }
    } else {
      this.activeMenuId = ''
    }
  },
  methods: {
    changeMenu(menu) {
      store.commit('setTab', menu)
    }
  }
}
</script>
<style scoped lang="scss">
.menu-box {
  width: 100%;
  height: calc(100% - 60px);
  background: #20222a;
  color: #909399;
  .menu {
    border-right: none;
    background: transparent;
    .sub-menu {
      background: transparent;
    }
  }
}
.icon {
  margin-right: 5px;
  font-size: 14px;
}

.menu-item {
  background-color: #20222a;
  i,
  span {
    color: hsla(0, 0%, 100%, 0.7);
  }
  &:hover {
    i,
    span {
      color: #fff;
    }
  }
  &.active {
    background: rgba(0, 0, 0, 0.9);
    &:before {
      content: '';
      top: 0;
      left: 0;
      bottom: 0;
      width: 4px;
      background: #409eff;
      position: absolute;
    }
    i,
    span {
      color: #fff;
    }
  }
}
::v-deep .el-submenu__title {
  background: transparent;
  color: #909399;
}
</style>
