<template>
  <div class="content-container">
    <div class="wrapper-common wrapper-memory">
      <div class="common-layout-container">
        <div class="memory-reflash">
          <el-button type="primary" size="small" plain @click="reflashMemory">刷新所有缓存</el-button>
        </div>
        <div class="inforedis">
          <i class="el-icon-warning-outline" aria-hidden="true">提示信息</i>
          <br />&nbsp;&nbsp;&nbsp;&nbsp;确认刷新后
          <br />&nbsp;&nbsp;&nbsp;1：系统会清除内存中缓存的数据，包括菜单、角色、机构、字典、授权信息等。
          <br />&nbsp;&nbsp;&nbsp;2：从数据库重新加载数据到内存中。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    reflashMemory() {
      this.$confirm('确认刷新吗？', {
        confirmButtonClass: 'el-button--danger',
        center: true,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = '执行中...'
            // delete
            // refreshRedis().then(() => {
            this.$message.success('操作成功')
            done()
            setTimeout(() => {
              instance.confirmButtonLoading = false
            }, 300)
            // })
          } else {
            done()
          }
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
  .content-container {
    .wrapper-memory {
      .memory-reflash {
        padding-top: 50px;
        width: 100%;
        vertical-align: middle;
        /* text-align: center; */
        margin-left: 20px;
      }
      .inforedis {
        width: 100%;
        color: #7d5b4f;
        margin-top: 20px;
        border-top: 1px solid grey;
        padding: 20px;
        line-height: 2;
      }
    }
  }
</style>
