<template>
  <div class="home">
    home
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
<style scoped lang="scss">
    .home {
        width: 100%;
        padding: 0;
        margin: 0;
    }
</style>
