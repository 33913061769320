<template>
  <el-dialog
    title="票价设置"
    :visible.sync="visible"
    width="800px"
    :before-close="handleClose"
  >
    <el-form ref="addForm" label-width="150px" style="margin:0 auto;">
      <el-row>
        <el-col :span="24">
          <el-form-item label="线路：">
            <el-select v-model="currModel.lineId" size="small" style="width: 100%;" @change="changeLine">
              <el-option v-for="line in lines" :key="line.id" :value="line.id" :label="line.lineName"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="起点站：">
            <el-select v-model="currModel.startStopId" size="small" style="width: 100%;">
              <el-option v-for="stop in stops" :key="stop.id" :value="stop.id" :label="stop.stopName"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="终点站：">
            <el-select v-model="currModel.endStopId" size="small" style="width: 100%;">
              <el-option v-for="stop in stops" :key="stop.id" :value="stop.id" :label="stop.stopName"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="排序：">
            <el-input-number v-model="currModel.orderBy" size="small" style="width: 100%;" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="价格：">
            <el-input-number v-model="currModel.price" size="small" style="width: 100%;" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose()">取 消</el-button>
      <el-button size="small" type="primary" @click="doSave()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { save } from '@/api/school-bus/price'
import { all as allLines } from '@/api/school-bus/line'
import { getStopsByLineId } from '@/api/school-bus/lineStop'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default: () => {
        return { id: null }
      }
    }
  },
  data() {
    return {
      currModel: {},
      lines: [],
      stops: []
    }
  },
  watch: {
    model(newVal) {
      console.log(this.currModel)
      this.currModel = newVal
    }
  },
  created() {
    this.getAllLines()
  },
  methods: {
    getAllLines() {
      allLines().then((res) => {
        if (res && res.status === 200) {
          this.lines = res.data

          const line = res.data[0]
          this.getStopsByLine(line.id)
        }
      })
    },
    getStopsByLine(lineId) {
      getStopsByLineId({ lineId: lineId }).then((res) => {
        if (res && res.status === 200) {
          this.stops = res.data
        }
      })
    },
    // 下拉事件
    changeLine(val) {
      this.getStopsByLine(val)
    },
    handleClose() {
      this.$emit('closeDialog')
    },
    doSave() {
      const params = {}
      const currLines = this.lines.filter(item => item.id === this.currModel.lineId)
      const currStartStops = this.stops.filter(item => item.id === this.currModel.startStopId)
      const currEndStops = this.stops.filter(item => item.id === this.currModel.endStopId)

      if (currLines && currLines.length > 0) {
        params.lineId = currLines[0].id
        params.lineName = currLines[0].lineName
      }
      if (currStartStops && currStartStops.length > 0) {
        params.startStopId = currStartStops[0].id
        params.startStopName = currStartStops[0].stopName
      }
      if (currEndStops && currEndStops.length > 0) {
        params.endStopId = currEndStops[0].id
        params.endStopName = currEndStops[0].stopName
      }
      params.id = this.currModel.id
      params.price = this.currModel.price
      params.orderBy = this.currModel.orderBy
      params.children = []
      save(params).then(res => {
        this.$message.success('保存成功！')
        this.$emit('closeDialog', true)
      }).catch((err) => {
        console.log(err)
        this.$message.error('保存失败！')
      })
    }
  }
}
</script>
<style scoped lang="scss">
</style>
