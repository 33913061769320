<template>
  <el-dialog
    title="角色基本信息"
    :visible.sync="visible"
    width="800px"
    :before-close="handleClose"
  >
    <el-form ref="addForm" label-width="90px" style="margin:0 auto;">
      <el-row>
        <el-col :span="12">
          <el-form-item label="角色名">
            <el-input v-model="roleModel.roleName" size="small" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="上级角色">
            <zxl-select-tree
              :multiple="false"
              :obj="roleProps"
              :tree-data="roleTree"
              :cur-value="roleModel.parentId"
              @getValue="setParentRole"
            ></zxl-select-tree>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="排序">
            <el-input-number v-model="roleModel.orderBy" type="number" size="small" style="width: 100%;" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="状态">
            <el-switch v-model="roleModel.status" size="small" :active-value="1" :inactive-value="0" active-color="#409EFF" inactive-color="#F56C6C" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose()">取 消</el-button>
      <el-button size="small" type="primary" @click="doSave()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { saveRole, getRoleTree } from '@/api/system/role'
import zxlSelectTree from '@/components/common/zxl-select-tree.vue'
export default {
  components: {
    zxlSelectTree
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    role: {
      type: Object,
      default: () => {
        return { id: null }
      }
    }
  },
  data() {
    return {
      roleTree: {},
      // parentRole: {},
      roleModel: {},
      roleProps: {
        id: 'id',
        label: 'roleName',
        children: 'children'
      }
    }
  },
  watch: {
    role(newVal) {
      this.roleModel = newVal
    }
  },
  created() {
    this.getRoleTree()
  },
  methods: {
    getRoleTree() {
      getRoleTree().then(res => {
        if (res && res.status === 200) {
          this.roleTree = res.data
        }
      })
    },
    setParentRole(dataKeys, datas) {
      if (dataKeys) {
        this.roleModel.parentId = dataKeys
      } else {
        this.roleModel.parentId = null
      }
    },
    handleClose() {
      this.$emit('closeDialog')
    },
    doSave() {
      const params = {
        id: this.roleModel.id,
        parentId: this.roleModel.parentId,
        roleName: this.roleModel.roleName,
        roleType: this.roleModel.roleType,
        creator: this.roleModel.creator,
        createTime: this.roleModel.createTime,
        orderBy: this.roleModel.orderBy,
        status: this.roleModel.status
      }
      saveRole(params).then(res => {
        if (res.status === 200) {
          this.$message.success('保存成功！')
        } else {
          this.$message.error('保存失败！')
        }
        this.$emit('closeDialog', true)
      }).catch(err => {
        console.error(err)
        this.$message.error('保存失败！')
      })
    }
  }
}
</script>
<style scoped lang="scss">
</style>
