<template>
  <el-dialog
    title="机构基本信息"
    :visible.sync="visible"
    width="800px"
    :before-close="handleClose"
  >
    <el-form ref="addForm" label-width="90px" style="margin:0 auto;">
      <el-row>
        <el-col :span="12">
          <el-form-item label="机构名称">
            <el-input v-model="orgModel.orgName" size="small" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="上级机构">
            <!-- <el-cascader v-model="parentOrgs" :options="orgTree" :props="orgProps" style="width:100%;" :show-all-levels="false"></el-cascader> -->
            <zxl-select-tree
              :multiple="false"
              :obj="orgProps"
              :tree-data="orgTree"
              :cur-value="orgModel.parentId"
              @getValue="setParentOrg"
            ></zxl-select-tree>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="排序">
            <el-input-number v-model="orgModel.orderBy" type="number" size="small" style="width: 100%;" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="状态">
            <el-switch v-model="orgModel.status" size="small" :active-value="1" :inactive-value="0" active-color="#409EFF" inactive-color="#F56C6C" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose()">取 消</el-button>
      <el-button type="primary" @click="doSave()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { saveOrg, getOrgTree } from '@/api/system/org'
import zxlSelectTree from '@/components/common/zxl-select-tree.vue'
export default {
  components: {
    zxlSelectTree
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    org: {
      type: Object,
      default: () => {
        return { id: null }
      }
    }
  },
  data() {
    return {
      orgTree: {},
      orgModel: {},
      // parentOrgs: [],
      orgProps: {
        id: 'id',
        label: 'orgName',
        children: 'children'
      }
    }
  },
  watch: {
    org(newVal) {
      this.orgModel = newVal
    }
  },
  created() {
    this.getOrgTree()
  },
  methods: {
    getOrgTree() {
      getOrgTree().then(res => {
        if (res && res.status === 200) {
          this.orgTree = res.data
        }
      })
    },
    setParentOrg(dataKeys, datas) {
      if (dataKeys) {
        this.orgModel.parentId = dataKeys
      } else {
        this.orgModel.parentId = null
      }
    },
    handleClose() {
      this.$emit('closeDialog')
    },
    doSave() {
      // this.orgModel.children = []
      saveOrg(this.orgModel).then(res => {
        if (res.status === 200) {
          this.$message.success('保存成功！')
        }
        this.$emit('closeDialog')
      }).catch(err => {
        console.error(err)
        this.$message.error('保存失败！')
      })
    }
  }
}
</script>
<style scoped lang="scss">
</style>
