<template>
  <div class="login-window">
    <el-carousel class="login-bg" height="100%">
      <el-carousel-item>
        <img src="../../assets/images/bgc.png" />
      </el-carousel-item>
      <!-- <el-carousel-item>
        <img src="../../assets/images/bgc2.jpg" />
      </el-carousel-item>
      <el-carousel-item>
        <img src="../../assets/images/bgc3.jpg" />
      </el-carousel-item> -->
    </el-carousel>
    <div class="login-box">
      <div class="sys-title">{{ sysTitle }}</div>
      <el-form ref="loginForm" :model="userInfo" :rules="loginRules" autocomplete="on" label-position="left" class="login-form">
        <el-form-item prop="username">
          <span slot="label" class="form-label">
            用户名：
          </span>
          <span>
            <el-input id="username" v-model="userInfo.username" name="用户名" type="text" placeholder="请输入用户名">
              <span slot="prefix" class="show-pwd">
                <i class="icon iconfont-system mingcheng" />
              </span>
            </el-input>
          </span>
        </el-form-item>
        <el-form-item prop="password">
          <span slot="label" class="form-label">
            密码：
          </span>
          <span>
            <el-input id="password" v-model="userInfo.password" :type="passwordType" placeholder="请输入密码" @keyup.enter.native="doEnter">
              <span slot="prefix" class="show-pwd">
                <i class="icon iconfont-system mima" />
              </span>
              <span slot="suffix" class="show-pwd" @click="showPwd">
                <i :class="passwordType === 'password' ? 'icon iconfont-system bukejian' : 'icon iconfont-system kejian'" />
              </span>
            </el-input>
          </span>
        </el-form-item>
        <el-form-item>
          <span>
            &nbsp;
          <!-- <el-checkbox label="记住密码" style="color: #fff;"></el-checkbox> -->
          </span>
          <el-button type="primary" class="login-submit" @click="doLogin">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import md5 from 'js-md5'
import { login } from '@/api/system/user'
import { setToken } from '@/utils/auth'
import store from '@/store'
export default {
  data() {
    return {
      sysTitle: process.env.VUE_APP_SYSTEM_TITLE,
      userInfo: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur', message: '请输入用户名' }],
        password: [{ required: true, trigger: 'blur', message: '请输入密码' }]
      },
      passwordType: 'password'
    }
  },
  methods: {
    doEnter(evt) {
      this.doLogin()
    },
    doLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          const params = {
            username: this.userInfo.username,
            password: md5(this.userInfo.password)
          }
          login(params).then(res => {
            if (res && res.status === 200) {
              const user = res.data
              setToken(user.id)
              store.commit('setUserInfo', user)
              localStorage.setItem('userInfo', JSON.stringify(user))
              this.$router.push({ path: '/school-bus' })
            } else {
              this.$message.error('账号或密码错误')
            }
          }).catch((err) => {
            console.error(err)
            this.$message.error('系统出错，请检查服务器是否运行')
          })
        }
      })
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
    }
  }
}
</script>
<style scoped lang="scss">
  .login-window {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    position: absolute;
    .login-bg {
      width: 100%;
      height: 100%;
      position: absolute;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .login-box {
      position: fixed;
      z-index: 2;
      width: 600px;
      height: 400px;
      left: 50%;
      top: 50%;
      margin-left: -300px;
      margin-top: -200px;
      .form-label {
        color: #fff;
      }
      i {
        cursor: pointer;
      }
      .sys-title {
        width: 100%;
        height: 60px;
        line-height: 60px;
        font-size: 40px;
        text-align: center;
        color: #fff;
      }
      .login-form {
        width: 400px;
        margin-left: 100px;
        color: #fff;
        .login-submit {
          width: 100%;
        }
      }
    }
  }
</style>
