<template>
  <div class="sub-content-container">
    <div class="toolbar-container">
      <search-box style="float: right; margin-right: 35px;" @doSearch="doSearchUser" />
    </div>
    <div class="table-container">
      <el-table
        ref="treeTable"
        border
        :row-key="'id'"
        :data="tableData"
        :row-class-name="tableRowClassName"
        v-bind="$attrs"
        size="small"
        highlight-current-row
        :header-cell-style="{ background: '#FAFAFA' }"
        class="table"
        @selection-change="handleSelectionChange"
        @select="rowSelect"
      >
        <el-table-column width="55" type="selection" reserve-selection align="center"></el-table-column>
        <el-table-column type="index" prop="id" label="序号" width="55" align="center"> </el-table-column>
        <el-table-column prop="username" label="用户名" align="center"> </el-table-column>
        <el-table-column prop="name" label="姓名" width="300" align="center"> </el-table-column>
        <el-table-column prop="createTime" label="创建时间" :formatter="$global.dateFormat" width="120" align="center"> </el-table-column>
        <el-table-column prop="status" label="状态" :formatter="statusFormat" width="100" align="center">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0" active-color="#409EFF" inactive-color="#F56C6C" disabled />
          </template>
        </el-table-column>
        <el-table-column width="130" label="操作" align="center">
          <template slot-scope="scope">
            <el-button v-if="scope.row.username !== 'admin'" type="text" size="mini" @click="delRelation(scope.row)">
              <i class="icon iconfont-system shanchu"></i>
              <span style="margin-left: 5px;">删除关联</span>
            </el-button>

          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      class="page-container"
      layout="total, sizes, prev, pager, next"
      :current-page="currPage"
      :page-size="pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import searchBox from '@/views/components/search-box.vue'
import { getRoleTree, getUsersByRoleId, getPageUsersByRoleId, deleteRole, deleteUserRole } from '@/api/system/role'
export default {
  components: {
    searchBox
  },
  props: {
    role: {
      type: Object,
      default: () => {
        return { id: null }
      }
    }
  },
  data() {
    return {
      loading: false,
      // 用户
      searchKeyUser: '',
      tableData: [],
      currPage: 1,
      pageSize: 10,
      total: 0
    }
  },
  watch: {
    role(newVal) {
      this.getUsersByRole(1, newVal)
    }
  },
  methods: {
    getUsersByRole(pageIndex, role) {
      this.loading = true
      this.currPage = pageIndex
      const params = {
        roleId: role.id,
        pageIndex: pageIndex,
        pageSize: this.pageSize,
        key: this.searchKeyUser
      }
      getPageUsersByRoleId(params).then(res => {
        if (res && res.status === 200) {
          this.tableData = res.data.records
          this.total = res.data.total
        }
        this.loading = false
      }).catch(err => {
        console.error(err)
        this.loading = false
      })
    },
    doSearchUser(searchKey) {
      this.searchKeyUser = searchKey
      this.getUsersByRole(1, this.role)
    },
    delRelation(user) {
      this.$confirm('删除后无法恢复, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const params = {
          userId: user.id,
          roleId: this.role.id
        }
        deleteUserRole(params).then((res) => {
          if (res && res.status === 200) {
            this.getRoleTree()
            this.$message({ message: '删除成功', type: 'success' })
          } else {
            this.$message({ message: '删除失败', type: 'error' })
          }
        }).catch((err) => {
          console.error(err)
          this.$message({ message: '删除失败', type: 'error' })
        })
      }).catch(() => {
        // 取消
      })
    },
    handleCurrentChange(pageIndex) {
      this.getUsersByRole(pageIndex, this.role)
    },
    handleSizeChange(size) {
      this.pageSize = size
      this.getUsersByRole(1, this.role)
    },
    statusFormat(row) {
      if (row && row.status) {
        if (row.status === 1) {
          return '启用'
        }
      } else {
        return '禁用'
      }
    },
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 === 0) {
        return 'odd'
      }
      return ''
    },
    handleSelectionChange() {

    },
    rowSelect() {

    }
  }
}
</script>
<style scoped lang="scss">
</style>
