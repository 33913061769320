export function dateFormat(row, column) {
  if (column && column.property) {
    if (row[column.property]) {
      const date = new Date(row[column.property])
      return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    } else {
      return ''
    }
  }
}
export function timeFormat(date) {
  const numF = (str, len) => {
    let res = str + ''
    while (res.length < len) {
      res = '0' + res
    }
    return res
  }
  return numF(date.getHours()) + ':' + numF(date.getMinutes(), 2) + ':' + numF(date.getSeconds())
}

export function monday() {
  // 获取当前时间
  const currentDate = new Date()

  // 获取当前日期的年、月、日
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth()
  const date = currentDate.getDate()

  // 获取当前日期的星期几（0表示周日，1表示周一，以此类推）
  const dayOfWeek = currentDate.getDay()

  // 计算本周的周一和周日的日期
  const monday = new Date(year, month, date - dayOfWeek + 1)
  return monday.getFullYear() + '-' + (monday.getMonth() + 1) + '-' + monday.getDate() + ' 00:00:00'
}
export function sunday() {
  // 获取当前时间
  const currentDate = new Date()

  // 获取当前日期的年、月、日
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth()
  const date = currentDate.getDate()

  // 获取当前日期的星期几（0表示周日，1表示周一，以此类推）
  const dayOfWeek = currentDate.getDay()

  // 计算本周的周一和周日的日期
  const sunday = new Date(year, month, date + (7 - dayOfWeek))
  return sunday.getFullYear() + '-' + (sunday.getMonth() + 1) + '-' + sunday.getDate() + ' 23:59:59'
}
