import axios from 'axios'
import Vue from 'vue'
import store from '../store'

const getBaseUrl = () => {
  return process.env.VUE_APP_BACK_URL
}

// create an axios instance
const service = axios.create({
  baseURL: getBaseUrl(), // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json'
    if (store.state.global.userinfo) {
      config.headers['userId'] = store.state.global.userinfo.id
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    return res
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
