<template>
  <span class="header-logo">
    <img src="@/assets/logo.png" class="header-logo-img" />
    <label class="header-title">{{ sysTitle }}</label>
  </span>
</template>

<script>
export default {
  data() {
    return {
      sysTitle: process.env.VUE_APP_SYSTEM_TITLE
    }
  }
}
</script>
<style lang="scss" scoped>
  .header-logo {
    display: flex;
    max-width: 680px;
    height: 60px;
    line-height: 60px;
    color: #fff;
    .iconfont {
      font-size: 24px;
      margin: 0 10px 0 10px;
    }
    .header-logo-img {
      width: 58px;
      height: 60px;
      margin: 0 0 0 10px;
    }
    .header-title {
      margin-left: 5px;
      font-family: 宋体;
      font-size: 28px;
      cursor: pointer;
    }
  }
</style>
