<template>
  <div class="search-box-outer" :style="'width: ' + width + '; height: ' + height + '; line-height: ' + height">
    <el-input v-model="keyWord" :placeholder="placeholder" class="search-box-input" :style="'height: ' + height" @keyup.native="doSearch" />
    <i v-if="keyWord === ''" class="icon iconfont-system sousuo" @click="doSearch()" />
    <i v-else class="icon iconfont-system cuohao" @click="doReset()" />
  </div>
</template>

<script>
export default {
  model: {
    prop: 'keyWord',
    event: 'input'
  },
  props: {
    width: {
      type: String,
      default: '300px'
    },
    height: {
      type: String,
      default: '35px'
    },
    placeholder: {
      type: String,
      default: '输入关键字进行搜索'
    }
  },
  data() {
    return {
      keyWord: ''
    }
  },
  methods: {
    doSearch() {
      this.$emit('doSearch', this.keyWord)
    },
    doReset() {
      this.keyWord = ''
      this.$emit('doSearch', this.keyWord)
    }
  }
}
</script>
<style scoped lang="scss">
  .search-box-outer {
    display: flex;
    flex-direction: row;
    height: 40px;
    line-height: 40px;
    padding-left: 0px;
    border: solid 1px #ccc;
    border-radius: 6px;
    .search-box-input {
      width: calc(100% - 24px);
      border-radius: 6px;
    }
    ::v-deep .el-input__inner {
      height: 20px;
      background-color: transparent;
      background-image: none;
      border: 0px; // 改成0，边框就消失了！
      width: 100%;
    }
    .icon {
      width: 24px;
      height: 24px;
      color: #ccc;
      cursor: pointer;
      &:hover {
        color: #708CB8;
      }
    }
  }
</style>
