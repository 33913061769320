import request from '@/utils/request'
const Qs = require('qs')

// 所有机构
export function getAllOrgs(data) {
  return request({
    url: '/rumpson-system/rest/sysOrg/all',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 机构树
export function getOrgTree(data) {
  return request({
    url: '/rumpson-system/rest/sysOrg/tree',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 机构层级
export function getOrgLevel(data) {
  return request({
    url: '/rumpson-system/rest/sysOrg/getOrgLevel',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 机构用户
export function getUsersByOrgId(data) {
  return request({
    url: '/rumpson-system/rest/sysOrg/getUsersByOrgId',
    method: 'post',
    data: Qs.stringify(data)
  })
}
// 删除关联
export function deleteUserOrg(data) {
  return request({
    url: '/rumpson-system/rest/sysOrg/deleteUserOrg',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 查询机构
export function queryOrg(data) {
  return request({
    url: '/rumpson-system/rest/sysOrg/query',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 保存机构
export function saveOrg(data) {
  return request({
    url: '/rumpson-system/rest/sysOrg/save',
    method: 'post',
    data: Qs.stringify(data)
  })
}
// 删除机构
export function deleteOrg(data) {
  return request({
    url: '/rumpson-system/rest/sysOrg/delete',
    method: 'post',
    data: Qs.stringify(data)
  })
}
