import request from '@/utils/request'
import requestJson from '@/utils/requestJson'
const Qs = require('qs')

// 获取登录日志列表
export function listLogLogin(data) {
  return requestJson({
    url: '/rumpson-system/rest/sysLogLogin/listLog',
    method: 'post',
    data: data // Qs.stringify(data)
  })
}

// 获取单条登录日志
export function getLogLoginById(data) {
  return request({
    url: '/rumpson-system/rest/sysLogLogin/findById',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 获取操作日志列表
export function listLogApi(data) {
  return requestJson({
    url: '/rumpson-system/rest/sysLogApi/listLogApi',
    method: 'post',
    data: data // Qs.stringify(data)
  })
}

// 获取单条操作日志
export function getLogApiById(data) {
  return request({
    url: '/rumpson-system/rest/sysLogApi/findById',
    method: 'post',
    data: Qs.stringify(data)
  })
}
// 获取错误日志列表
export function listLogError(data) {
  return requestJson({
    url: '/rumpson-system/rest/sysLogError/listLog',
    method: 'post',
    data: data // Qs.stringify(data)
  })
}

// 获取单条错误日志
export function getLogErrorById(data) {
  return request({
    url: '/rumpson-system/rest/sysLogError/findById',
    method: 'post',
    data: Qs.stringify(data)
  })
}
