<template>
  <div class="system" :style="{ height: systemHeight }">
    <Header :app-name="appName" />
    <div class="center">
      <div class="aside">
        <Menu :menus="menus" @changeMenu="changeMenu" />
      </div>
      <div class="content">
        <tab-bar ref="systemTabBar" :default-tab="defaultTab" @toggleTab="toggleTab" />
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/header/index'
import Menu from '@/components/menu/index'
import TabBar from '@/views/components/tab-bar'
import store from '@/store'
export default {
  name: 'System',
  components: {
    Header,
    Menu,
    TabBar
  },
  data() {
    return {
      appName: '基础运维',
      menuName: '用户管理',
      menus: [],
      defaultTab: {}
    }
  },
  computed: {
    systemHeight() {
      const h = (window.innerHeight - 40) + 'px'
      return h
    }
  },
  created() {
    const menus = store.state.global.dynamicRoutes.filter(item => item.name === this.appName)[0].children
    this.menus = menus
    if (this.menus && this.menus.length > 0) {
      if (this.menus[0].children && this.menus[0].children.length > 0) {
        this.defaultTab = this.menus[0].children[0]
      } else {
        this.defaultTab = this.menus[0]
      }
    } else {
      this.defaultTab = {}
    }
    store.toolbar = this.$refs.systemTabBar

    console.log('this.$router', store.state)

    if (store.state.global.userinfo.username !== 'admin') {
      this.$router.push({
        path: '/system/todo'
      })
    } else {
      this.$router.push({
        path: '/system/orup/user-manage'
      })
    }
  },
  methods: {
    changeMenu(menu) {
      this.$refs.systemTabBar.toggleTab(menu)
    },
    toggleTab(tab) {

    }
  }
}
</script>
<style scoped lang="scss">
.system {
  width: 100%;
  .center {
    display: flex;
    flex-direction: row;
    height: 100%;
    .aside {
      width: 300px;
      height: calc(100%);
      // background: rgb(0, 166, 214);
    }
    .content {
      width: calc(100% - 300px);
      height: calc(100%);
      background: #eee;
    }
  }
}
</style>
