import request from '@/utils/request'
const Qs = require('qs')

// 所有
export function all(data) {
  return request({
    url: '/school-bus/rest/stop/all',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 分页
export function page(data) {
  return request({
    url: '/school-bus/rest/stop/page',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 保存
export function save(data) {
  return request({
    url: '/school-bus/rest/stop/save',
    method: 'post',
    data: Qs.stringify(data)
  })
}
// 删除
export function del(data) {
  return request({
    url: '/school-bus/rest/stop/delete',
    method: 'post',
    data: Qs.stringify(data)
  })
}
