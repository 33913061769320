import axios from 'axios'
import md5 from 'js-md5'
import Base64 from './base64'

export function sendSms(phone, content) {
  return new Promise((resolve, reject) => {
    const serviceCode = '10657204006' // 服务代码
    const extendCode = '' // 扩展代码，服务代码为精确匹配就填‘’,
    const mac = md5('接口联调账号psxzfwPasswd@#123' + phone + content + 'bM16CfN2B' + extendCode)
    const params = {
      ecName: '接口联调账号',
      apId: 'psxzfw',
      mobiles: phone,
      content: content,
      sign: 'bM16CfN2B',
      addSerial: extendCode,
      mac: mac
    }
    const url = '/sms/submit'
    axios.post(url, Base64.encode(JSON.stringify(params))).then((res) => {
      if (res && res.status === 200) {
        resolve(res.data)
      }
    }).catch((err) => {
      reject(err)
    })
  })
}
export function getSms() {
  return new Promise((resolve, reject) => {
    const params = {
      ecName: '接口联调账号',
      apId: 'psxzfw',
      secretKey: 'Passwd@#123'
    }
    const url = '/sms/deliver'
    axios.post(url, Base64.encode(JSON.stringify(params))).then((res) => {
      if (res) {
        resolve(res.data)
      }
    }).catch((err) => {
      reject(err)
    })
  })
}
