<template>
  <div id="app">
    <div class="app-content">
      <router-view />
    </div>
    <div class="app-footer">
      <a target="_blank" href="https://beian.miit.gov.cn">陇ICP备2024007517号-1</a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style lang="scss" scoped>
#app {
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: hidden;
  .app-content {
    width: 100%;
    height: calc(100% - 40px);
    background: #fff;
    overflow: hidden;
  }
  .app-footer {
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    bottom: 0;
    left: 0;
    background: #20222a;
    color: #fff;
    border-top: solid 1px #555;
  }
}

::v-deep .el-tree-node__content {
  &:hover {
    background-color: #439057;
  }
}
::v-deep .el-tree-node:focus>.el-tree-node__content {
    background-color: #439057;
}
</style>
