<template>
  <div class="change-pwd" :style="{ height: approveHeight }">
    <Header :app-name="menuName" />
    <div class="center">
      <div class="change-pwd-content">
        <el-form ref="form-change-pwd" :model="pwdModel" :rules="rules" class="change-pwd-form">
          <el-form-item label="旧密码" prop="oldPwd">
            <el-input
              v-model="pwdModel.oldPwd" type="password" auto-complete="off"
              show-password
            />
          </el-form-item>
          <el-form-item label="新密码" prop="newPwd">
            <el-input v-model="pwdModel.newPwd" type="password" show-password />
          </el-form-item>
          <el-form-item label="确认新密码" prop="confirmNewPwd">
            <el-input v-model="pwdModel.confirmNewPwd" type="password" show-password />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" style="width: 100%;" @click="doChangePwd">确定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import Header from '@/components/header/index'
import md5 from 'js-md5'
import { chnagePwd } from '@/api/system/user'
export default {
  name: 'ChangePwd',
  components: {
    Header
  },
  data() {
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.pwdModel.newPwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      menuName: '修改密码',
      pwdModel: {
        oldPwd: '',
        newPwd: '',
        confirmNewPwd: ''
      },
      rules: {
        oldPwd: [
          { required: true, message: '请输入旧密码', trigger: 'blur' }
        ],
        newPwd: [
          { required: true, message: '请输入新密码', trigger: 'blur' }
        ],
        confirmNewPwd: [
          { required: true, message: '请确认新密码', trigger: 'blur' },
          { validator: validatePass2, trigger: 'blur', required: true }
        ]
      }
    }
  },
  computed: {
    defaultTab() {
      return this.menus[0].children[0]
    },
    approveHeight() {
      const h = window.innerHeight + 'px'
      return h
    }
  },
  mounted() {
    store.toolbar = this.$refs.approveTabBar
  },
  methods: {
    doChangePwd() {
      this.$refs['form-change-pwd'].validate((valid) => {
        if (valid) {
          console.log(store)
          const temp = {}
          temp.userId = store.state.global.userinfo.id
          temp.oldPwd = md5(this.pwdModel.oldPwd)
          temp.newPwd = md5(this.pwdModel.newPwd)
          temp.confirmNewPwd = md5(this.pwdModel.confirmNewPwd)
          chnagePwd(temp).then(res => {
            if (res && res.status === 200) {
              this.$message.success('修改成功！')
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
    .change-pwd {
        width: 100%;
        .center {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 100%;
            background: #eee;
            .change-pwd-content {
                width: calc(100% - 10px);
                height: calc(100% - 70px);
                margin: 5px;
                background: #fff;
                .change-pwd-form {
                    width: 400px;
                    height: 250px;
                    margin-left: calc((100% - 410px) / 2);
                    margin-top: 50px;
                }
            }
        }
    }
</style>
