<template>
  <div class="content-container">
    <div class="toolbar-container">
      <search-box v-model="searchKey" style="float: right; margin-right: 35px;" @doSearch="doSearch" />
      <el-button type="primary" size="mini" style="float: left;" @click="doAdd()">
        <i class="icon iconfont-system xinzeng" />
        <span>添加</span>
      </el-button>
      <el-button type="primary" size="mini" style="float: left;" @click="doImport()">
        <i class="icon iconfont-system daoru" />
        <span>导入</span>
      </el-button>
      &nbsp;
      <a href="/static/学生信息导入模板.xlsx" rel="external nofollow" download="学生信息导入模板" style="text-decoration: underline; line-height: 35px;">下载模板文件</a>
      <el-upload
        ref="fileRef"
        action="#"
        :limit="1"
        accept=".xlsx"
        :file-list="files"
        :http-request="doUpload"
      >
      </el-upload>
    </div>
    <div class="table-container">
      <el-table
        ref="treeTable"
        v-loading="loading"
        border
        size="small"
        highlight-current-row
        :row-key="'id'"
        :data="tableData"
        :row-class-name="tableRowClassName"
        :header-cell-style="{ background: '#FAFAFA' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column width="55" type="selection" reserve-selection align="center"></el-table-column>
        <el-table-column type="index" prop="id" label="序号" width="55" align="center"> </el-table-column>
        <el-table-column prop="studentNo" label="学号" width="150" align="center"> </el-table-column>
        <el-table-column prop="name" label="姓名" align="left"> </el-table-column>
        <el-table-column prop="grade" label="年级" width="150" align="left"> </el-table-column>
        <el-table-column prop="cls" label="班级" width="150" align="left"> </el-table-column>
        <el-table-column prop="tel" label="联系方式" width="150" align="left"> </el-table-column>
        <el-table-column prop="contact" label="紧急联系人" width="150" align="left"> </el-table-column>
        <el-table-column prop="contactTel" label="紧急人联系方式" width="150" align="left"> </el-table-column>
        <el-table-column prop="orderBy" label="排序" width="80" align="center"> </el-table-column>
        <el-table-column prop="status" label="状态" :formatter="statusFormat" width="100" align="center">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" size="mini" :active-value="1" :inactive-value="0" active-color="#409EFF" inactive-color="#F56C6C" disabled />
          </template>
        </el-table-column>
        <el-table-column width="220" label="操作" align="center">
          <template v-if="scope.row.username !== 'admin'" slot-scope="scope">
            <el-button type="text" size="mini" @click="editStudent(scope.row)">
              <i class="icon iconfont-system xiugai"></i>
              <span style="margin-left: 5px;">修改</span>
            </el-button>
            <el-button type="text" size="mini" @click="editPwd(scope.row)">
              <i class="icon iconfont-system mima"></i>
              <span style="margin-left: 5px;">修改密码</span>
            </el-button>
            <el-button type="text" size="mini" @click="delStudent(scope.row)">
              <i class="icon iconfont-system shanchu"></i>
              <span style="margin-left: 5px;">删除</span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      class="page-container"
      :current-page="currPage"
      :page-size="pageSize"
      :total="total"
      :align="'right'"
      layout="total, sizes, prev, pager, next"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    >
    </el-pagination>
    <edit-student :visible="showEditDialog" :model="currModel" @closeDialog="closeDialog" />
    <edit-pwd :visible="showPwdDialog" :model="currModel" @closeDialog="closeDialog" />
  </div>
</template>

<script>
import searchBox from '@/views/components/search-box.vue'
import editStudent from './edit-student.vue'
import editPwd from './edit-pwd.vue'
import { page, del, upload } from '@/api/school-bus/student'
export default {
  name: 'User',
  components: {
    searchBox,
    editStudent,
    editPwd
  },
  data() {
    return {
      tableData: [],
      currPage: 1,
      pageSize: 10,
      total: 0,
      searchKey: '',
      showEditDialog: false,
      showPwdDialog: false,
      currModel: {},
      files: [],
      loading: false
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.loading = true
      const params = {
        pageIndex: this.currPage,
        pageSize: this.pageSize,
        key: this.searchKey
      }
      page(params).then(res => {
        if (res && res.status === 200) {
          this.tableData = res.data.records
          this.total = res.data.total
        }
        this.loading = false
      }).catch((err) => {
        console.error(err)
        this.loading = false
      })
    },
    handleCurrentChange(current) {
      this.currPage = current
      this.loadData()
    },
    handleSizeChange(size) {
      this.pageSize = size
      this.loadData()
    },
    statusFormat(row) {
      if (row && row.status) {
        if (row.status === 1) {
          return '启用'
        }
      } else {
        return '禁用'
      }
    },
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 === 0) {
        return 'odd'
      }
      return ''
    },
    handleSelectionChange() {

    },
    doSearch(searchKey) {
      this.searchKey = searchKey
      this.loadData()
    },
    doAdd() {
      this.currModel = {
        status: 1
      }
      this.showEditDialog = true
    },
    doImport() {
      this.$refs.fileRef.$refs['upload-inner'].handleClick()
    },
    doUpload(e) {
      const fd = new FormData()
      fd.append('file', e.file)
      upload(fd).then((res) => {
        this.$message({ message: '导入成功', type: 'success' })
        setTimeout(() => {
          this.files = []
          this.loadData()
        }, 1000)
      })
    },
    editPwd(entity) {
      this.currModel = entity
      this.showPwdDialog = true
    },
    editStudent(entity) {
      this.currModel = entity
      this.showEditDialog = true
    },
    delStudent(entity) {
      this.$confirm('删除后无法恢复, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        del({ id: entity.id }).then(res => {
          if (res && res.status === 200) {
            this.currPage = 1
            this.loadData()
            this.$message({ message: '删除成功', type: 'success' })
          } else {
            this.$message({ message: '删除失败', type: 'error' })
          }
        }).catch((err) => {
          console.error(err)
          this.$message({ message: '删除失败', type: 'error' })
        })
      }).catch(() => {
        // 取消
      })
    },
    closeDialog(refresh) {
      this.showEditDialog = false
      this.showPwdDialog = false
      if (refresh) {
        this.loadData()
      }
    }
  }
}
</script>
<style scoped lang="scss">
</style>
