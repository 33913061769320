import Vue from 'vue'
import Router from 'vue-router'
// import approveRouter from './modules/approve'
Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export const constantRoutes = [
  {
    path: '/',
    component: () => import('@/views/home/index'),
    redirect: '/school-bus'
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401')
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404')
  },
  {
    path: '/login',
    component: () => import('@/views/login/index')
  },
  {
    path: '/changePwd',
    component: () => import('@/views/login/change-pwd')
  },
  {
    path: '/test',
    component: () => import('@/views/test/index')
  }
]

// export const asyncRoutes = [
//   ...approveRouter
// ]

const createRouter = () =>
  new Router({
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  })

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

router.beforeEach((to, from, next) => {
  next()
})

export default router
