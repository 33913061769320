import request from '@/utils/request'
const Qs = require('qs')

// 登录
export function login(data) {
  return request({
    url: '/rumpson-system/rest/sysUser/login',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 所有用户
export function getAllUsers(data) {
  return request({
    url: '/rumpson-system/rest/sysUser/all',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 分页用户
export function getPageUsers(data) {
  return request({
    url: '/rumpson-system/rest/sysUser/page',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 查询用户
export function queryUser(data) {
  return request({
    url: '/rumpson-system/rest/sysUser/query',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 保存用户
export function saveUser(data) {
  return request({
    url: '/rumpson-system/rest/sysUser/save',
    method: 'post',
    data: Qs.stringify(data)
  })
}
// 删除用户
export function deleteUser(data) {
  return request({
    url: '/rumpson-system/rest/sysUser/delete',
    method: 'post',
    data: Qs.stringify(data)
  })
}
// 改密码
export function chnagePwd(data) {
  return request({
    url: '/rumpson-system/rest/sysUser/chnagePwd',
    method: 'post',
    data: Qs.stringify(data)
  })
}
