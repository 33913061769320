<template>
  <el-dialog
    title="关联站点"
    :visible.sync="visible"
    width="800px"
    :before-close="handleClose"
  >
    <el-form ref="addForm" label-width="150px" style="margin:0 auto;">
      <el-row>
        <el-col :span="12">
          <el-form-item label="线路选择：">
            <el-select v-model="currModel.lineId" size="small" style="width: 230px;">
              <el-option v-for="line in lines" :key="line.id" :label="line.lineName" :value="line.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="站点选择：">
            <el-select v-model="currModel.stopId" size="small">
              <el-option v-for="stop in stops" :key="stop.id" :label="stop.stopName" :value="stop.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="排序：">
            <el-input-number v-model="currModel.orderBy" size="small" style="width: 100%;" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="状态：">
            <el-switch v-model="currModel.status" size="small" :active-value="1" :inactive-value="0" active-color="#409EFF" inactive-color="#F56C6C" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose()">取 消</el-button>
      <el-button size="small" type="primary" @click="doSave()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { all as allLines } from '@/api/school-bus/line'
import { all as allStops } from '@/api/school-bus/stop'
import { getStopsByLineId, save as saveLineStop, del } from '@/api/school-bus/lineStop'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default: () => {
        return { id: null }
      }
    }
  },
  data() {
    return {
      lines: [],
      stops: [],
      currModel: {
        status: 1
      },
      currLine: {},
      currStop: {}
    }
  },
  watch: {
    model(newVal) {
      this.currLine = newVal
      // 默认选中
      this.currModel.lineId = newVal.id
    }
  },
  mounted() {
    this.getAllLines()
    this.getAllstops()
  },
  methods: {
    handleClose() {
      this.$emit('closeDialog')
    },
    doSave() {
      this.currModel.children = []
      saveLineStop(this.currModel).then((res) => {
        this.$message.success('保存成功！')
        this.$emit('closeDialog', true)
      }).catch((err) => {
        console.log(err)
        this.$message.error('保存失败！')
      })
    },
    getAllLines() {
      allLines().then((res) => {
        if (res && res.status === 200) {
          this.lines = res.data
        }
      })
    },
    getAllstops() {
      allStops().then((res) => {
        if (res && res.status === 200) {
          this.stops = res.data
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
</style>
