<template>
  <div class="sub-content-container">
    <div v-loading="appLoading" class="left-container">
      <search-box width="240px" @doSearch="doSearchApp" />
      <el-tree
        ref="eltree"
        node-key="id"
        icon-class="custom"
        highlight-current
        class="data-tree"
        :data="apps"
        :props="treeprops"
        :default-expand-all="true"
        :expand-on-click-node="false"
        @node-click="changeApp"
      >
        <!-- 根目录 -->
        <span v-if="node.level === 1" slot-scope="{ node, data }" class="tree-item-content item-root">
          <i v-if="node.expanded" class="icon iconfont-system wenjianjiazhankai"></i>
          <i v-else class="icon iconfont-system wenjianjiaguanbi"></i>
          <span :title="data[treeprops.label]" class="label">{{ data[treeprops.label] }}</span>
        </span>
        <!-- 应用 -->
        <span v-else slot-scope="{ node, data }" class="tree-item-content item-app">
          <i class="icon iconfont-system zixitong"></i>
          <span :title="data[treeprops.label]" class="label">{{ data[treeprops.label] }}</span>
        </span>
      </el-tree>
    </div>
    <div v-loading="menuLoading" class="right-container">
      <div class="toolbar-container">
        <search-box style="float: right; margin-right: 35px;" @doSearch="doSearchMenu" />
        <el-button type="primary" size="mini" @click="saveRoleMenu()">
          <i class="icon iconfont-system baocun"></i>
          <span>保存</span>
        </el-button>
      </div>
      <div class="table-container">
        <el-table
          ref="treeTable"
          size="small"
          highlight-current-row
          border
          :row-key="'id'"
          :row-class-name="tableRowClassName"
          :data="menus"
          :default-expand-all="true"
          :header-cell-style="{ background: '#FAFAFA' }"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" reserve-selection width="55" align="center"></el-table-column>
          <el-table-column type="index" prop="index" label="序号" width="55" align="center"> </el-table-column>
          <el-table-column prop="menuName" label="菜单名称" align="left"> </el-table-column>
          <el-table-column prop="path" label="路由地址" width="200" align="left"> </el-table-column>
          <el-table-column prop="page" label="页面" width="280" align="left"> </el-table-column>
          <el-table-column prop="sort" label="排序" width="50" align="center"> </el-table-column>
          <el-table-column prop="category" label="菜单类型" width="80" align="center">
            <template slot-scope="scope">
              <el-tag>
                {{ filterMenuType(scope.row.category) }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态" width="100" :formatter="statusFormat" align="center">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0" active-color="#409EFF" inactive-color="#F56C6C" disabled />
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import searchBox from '@/views/components/search-box.vue'
import { list as listApp } from '@/api/system/app'
import { tree } from '@/api/system/menu'
import { save } from '@/api/system/role-menu'
import { listDictItemByType } from '@/api/system/dict'
export default {
  components: {
    searchBox
  },
  props: {
    role: {
      type: Object,
      default: () => {
        return { id: null }
      }
    },
    roleMenus: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      currRole: null,
      // 应用
      treeprops: {
        id: 'id',
        label: 'appName',
        children: 'children',
        type: 'parentId',
        isLeaf: 'leaf'
      },
      appLoading: false,
      appSearchKey: '',
      currApp: {},
      apps: [],
      // 菜单
      menuLoading: false,
      menuSearchKey: '',
      menus: [],
      menuTypes: [],
      // 权限
      rowSelectFlag: false, // 开关，解决被动触发selection-change导致选择重复问题
      roleMenuIds: []
    }
  },
  watch: {
    role(newVal) {
      this.currRole = newVal
    },
    currRole(newVal) {
      this.getApps()
    },
    roleMenus: {
      handler(newVal) {
        this.roleMenuIds = newVal
      },
      deep: true
    }
  },
  created() {
    this.getMenuTypes()
  },
  methods: {
    getMenuTypes() {
      const params = {
        typeCode: 'menu_type'
      }
      listDictItemByType(params).then(res => {
        if (res && res.status === 200) {
          this.menuTypes = res.data
        }
      })
    },
    getApps() {
      this.appLoading = true
      const params = {
        key: this.appSearchKey
      }
      listApp(params).then(res => {
        this.appLoading = false
        if (res && res.status === 200) {
          this.apps = [{
            id: '-1',
            appCode: 'appList',
            appName: '应用列表',
            children: res.data
          }]
          this.menus = []
          // if (res.data && res.data.length > 0) {
          //   this.currApp = res.data[0]
          //   this.getMenuTree()
          // }
        }
      })
    },
    // 切换应用
    changeApp(app) {
      this.currApp = app
      if (app.id) {
        this.getMenuTree()
      }
    },
    // 加载菜单树
    getMenuTree() {
      this.menuLoading = true
      const params = {
        key: this.menuSearchKey,
        appId: this.currApp.id
      }
      tree(params).then(res => {
        this.menuLoading = false
        if (res && res.status === 200) {
          this.menus = res.data
          this.changeSelect()
        }
      })
    },
    changeSelect() {
      this.rowSelectFlag = true
      this.$refs.treeTable.clearSelection() // 全不选
      this.menus.forEach((item) => {
        this.doSelection(item)
      })
      this.rowSelectFlag = false
    },
    doSelection(menu) {
      if (this.roleMenuIds.indexOf(menu.id) !== -1) {
        this.$refs.treeTable.toggleRowSelection(menu, true)
      } else {
        this.$refs.treeTable.toggleRowSelection(menu, false)
      }

      if (menu.children) {
        menu.children.forEach((child) => {
          this.doSelection(child)
        })
      }
    },
    filterMenuType(val) {
      return this.menuTypes.filter((item) => item.dictKey === `${val}`)[0].dictValue
    },
    doSearchApp(searchKey) {
      this.appSearchKey = searchKey
      this.getApps()
    },
    doSearchMenu(searchKey) {
      this.menuSearchKey = searchKey
      this.getMenuTree()
    },
    statusFormat(row) {
      if (row && row.status) {
        if (row.status === 1) {
          return '启用'
        }
      } else {
        return '禁用'
      }
    },
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 === 0) {
        return 'odd'
      }
      return ''
    },
    handleSelectionChange(selectedItems) {
      if (this.rowSelectFlag) return
      this.roleMenuIds = selectedItems.map((item) => {
        return item.id
      })
    },
    saveRoleMenu() {
      if (this.currRole.parentId === '-1') {
        this.$message({ message: '请先选中角色', type: 'error' })
      } else {
        const params = {
          roleId: this.currRole.id,
          appId: this.currApp.id,
          menuIds: this.roleMenuIds.join(',')
        }
        save(params).then((res) => {
          if (res && res.status === 200) {
            this.$emit('refreshSelect')
            this.$message({ message: '保存成功', type: 'success' })
          } else {
            this.$message({ message: res.message, type: 'error' })
          }
        }).catch((err) => {
          console.error(err)
          this.$message({ message: '保存失败', type: 'error' })
        })
      }
    }
  }
}
</script>
<style scoped lang="scss">
  @import '@/styles/tree.scss';
  .sub-content-container {
    display: flex;
    flex-direction: row;
    .left-container {
      width: 250px;
      height: calc(100%);
      border-right: solid 1px #eee;
      padding-left: 5px;
    }
    .right-container {
      flex: 1;
      // width: calc(100% - 250px);
    }
  }
</style>
