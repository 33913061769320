<template>
  <el-popover
    ref="popoverTree"
    v-model="isShowSelect"
    popper-class="selectTree"
    placement="bottom-start"
    trigger="manual"
    @hide="popoverHide"
  >
    <el-tree
      ref="tree"
      class="common-tree"
      :data="treeData"
      :props="obj"
      :show-checkbox="multiple"
      :node-key="obj.id"
      :check-strictly="checkStrictly"
      :highlight-current="true"
      @check-change="nodeClick"
      @node-click="nodeClick"
    >
    </el-tree>
    <template #reference>
      <el-select
        ref="select"
        v-model="returnDataKeys"
        size="small"
        :multiple="multiple"
        :placeholder="placeholder"
        style="width: 290px;"
        @click.native="selectClick"
      >
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
    </template>
  </el-popover>
</template>

<script>
export default {
  props: {
    // 树结构数据
    treeData: {
      type: Array,
      default() {
        return []
      }
    },
    curValue: {
      type: [Number, String, Array, Object],
      default() {
        return {}
      }
    },
    obj: {
      type: Object,
      required: false,
      default: () => {
        return {
          id: '', // id
          pid: '', // 父级id
          label: '', // 显示名称
          children: '' // 子级字段名
        }
      }
    },
    // 显示复选框情况下，是否严格遵循父子不互相关联
    checkStrictly: {
      type: Boolean,
      default() {
        return false
      }
    },
    radioStrictly: {
      type: Boolean,
      default() {
        return false
      }
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isShowSelect: false,
      options: [], // select option选项
      returnDatas: [], // 返回给父组件数组对象
      returnDataKeys: [], // 返回父组件数组主键值
      placeholder: '请选择'
    }
  },
  watch: {
    isShowSelect() {
      // 隐藏select自带的下拉框
      this.$refs.select.blur()
    },
    curValue(val) {
      this.$nextTick(() => {
        if (val) {
          if (typeof val !== 'string' && typeof val !== 'number') {
            this.setKeys(val)
          } else {
            this.setKey(val)
          }
        }
      })
    },
    treeData() {
      // 监听tree数据
      this.$nextTick(() => {
        this.init()
      })
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.curValue) {
        if (typeof this.curValue !== 'string' && typeof this.curValue !== 'number') {
          this.setKeys(this.curValue)
        } else {
          this.setKey(this.curValue)
        }
      }
      // 注册点击其他地方时关闭角色树事件
      window.addEventListener('click', this.handleClickOutside)
    })
  },
  destroyed() {
    // 取消注册点击其他地方时关闭角色树事件
    window.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    selectClick() {
      // 显示下拉框
      return (this.isShowSelect = !this.isShowSelect)
    },
    // 单选: 树点击方法
    nodeClick(data, node) {
      if (!this.multiple) {
        // 单选父子节点不关联
        if (this.radioStrictly) {
          this.setKey(node.key)
        } else {
          if (node.level !== 1) {
            this.setKey(node.key)
          } else if (node.level === 1 || this.obj.children === '') {
            this.setKey(node.key)
          }
        }
        this.isShowSelect = false
      } else {
        // 多选
        var checkedKeys = this.$refs.tree.getCheckedKeys() // 所有被选中的节点的 key 所组成的数组数据

        checkedKeys = checkedKeys.filter(function(s) {
          // eslint-disable-next-line valid-typeof
          if (typeof (s) === 'String') {
            return s && s.trim()
          } else {
            return s
          }
        })
        var t = []
        checkedKeys = this.unique(checkedKeys)
        this.options = checkedKeys.map(item => {
          if (item !== undefined) {
            var node = this.$refs.tree.getNode(item) // 所有被选中的节点对应的node
            t.push(node.data)
          }
          // 设置option选项
          return { label: node.label, value: node.key }
        })
        this.returnDataKeys = this.options.map(item => {
          return item.value
        })
        this.returnDatas = t
        this.popoverHide()
      }
    },
    unique(arr) {
      return arr.filter(function(item, index, arr) {
        return arr.indexOf(item, 0) === index
      })
    },
    // 单选:设置、初始化值 key
    setKey(thisKey) {
      this.$refs.tree.setCurrentKey(thisKey)
      var node = this.$refs.tree.getNode(thisKey)
      if (node) {
        this.setData(node.data)
      }
    },
    // 单选：设置、初始化对象
    setData(data) {
      this.options = []
      this.options.push({
        label: data[this.obj.label],
        value: data[this.obj.id]
      })
      this.returnDatas = data
      this.returnDataKeys = data[this.obj.id]
    },
    // 多选:设置、初始化值 keys
    setKeys(thisKeys) {
      this.$refs.tree.setCheckedKeys(thisKeys)
      this.returnDataKeys = thisKeys
      var t = []
      thisKeys.map(item => {
        // 设置option选项
        var node = this.$refs.tree.getNode(item) // 所有被选中的节点对应的node
        t.push(node.data)
        return { label: node.label, value: node.key }
      })
      this.returnDatas = t
      this.popoverHide()
    },
    // 多选:设置、初始化对象
    setDatas(data) {
      this.$refs.tree.setCheckedNodes(data)
      this.returnDatas = data
      var t = []
      data.map(item => {
        // 设置option选项
        t.push(item[this.obj.id])
      })
      this.returnDataKeys = t
      this.popoverHide()
    },
    init() {
      // debugger
      if (this.defaultKey !== undefined && this.defaultKey.length > 0) {
        if (this.multiple) {
          // 多选
          if (Object.prototype.toString.call(this.defaultKey).indexOf('Array') !== -1) {
            if (Object.prototype.toString.call(this.defaultKey[0]).indexOf('Object') !== -1) {
              // 对象
              this.setDatas(this.defaultKey)
            } else if (Object.prototype.toString.call(this.defaultKey[0]).indexOf('Number') !== -1 || Object.prototype.toString.call(this.defaultKey[0]).indexOf('String') !== -1) {
              this.setKeys(this.defaultKey)
            } else {
              console.log('多选：传入参数类型不匹配')
              return
            }
          } else {
            console.log('多选：传入参数类型不匹配')
            return
          }
        } else {
          // 单选
          if (
            Object.prototype.toString.call(this.defaultKey).indexOf('Number') !== -1 ||
            Object.prototype.toString.call(this.defaultKey).indexOf('String') !== -1 ||
            Object.prototype.toString.call(this.defaultKey).indexOf('Object') !== -1
          ) {
            this.setKey(this.defaultKey)
          } else {
            console.log('单选：传入参数类型不匹配')
            return
          }
        }
      }
    },
    // 下拉框关闭执行
    popoverHide() {
      this.$emit('getValue', this.returnDataKeys, this.returnDatas)
    },
    // 点击外部关闭
    handleClickOutside(e) {
      if (this.$refs.popoverTree && this.$refs.popoverTree.$el) {
        if (!this.$refs.popoverTree.$el.contains(e.target)) {
          this.isShowSelect = false
        }
      }
    }
  }
}
</script>
  <style scoped lang="scss">
      .common-tree {
        overflow: auto;
        width: 265px;
        max-height: 300px;
        height: auto;
      }
  </style>

