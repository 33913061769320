import request from '@/utils/request'
const Qs = require('qs')

// 所有
export function all(data) {
  return request({
    url: '/school-bus/rest/time/all',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 获取单条
export function get(data) {
  return request({
    url: '/school-bus/rest/time/get',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 保存
export function save(data) {
  return request({
    url: '/school-bus/rest/time/save',
    method: 'post',
    data: Qs.stringify(data)
  })
}
// 删除
export function del(data) {
  return request({
    url: '/school-bus/rest/time/delete',
    method: 'post',
    data: Qs.stringify(data)
  })
}
