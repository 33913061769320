import request from '@/utils/request'
const Qs = require('qs')

// 所有角色
export function getAllRoles(data) {
  return request({
    url: '/rumpson-system/rest/sysRole/all',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 角色树
export function getRoleTree(data) {
  return request({
    url: '/rumpson-system/rest/sysRole/tree',
    method: 'post',
    data: Qs.stringify(data)
  })
}
// 分页用户
export function getPageUsersByRoleId(data) {
  return request({
    url: '/rumpson-system/rest/sysRole/getPageUsersByRoleId',
    method: 'post',
    data: Qs.stringify(data)
  })
}
// 角色用户
export function getUsersByRoleId(data) {
  return request({
    url: '/rumpson-system/rest/sysRole/getUsersByRoleId',
    method: 'post',
    data: Qs.stringify(data)
  })
}
// 删除关联
export function deleteUserRole(data) {
  return request({
    url: '/rumpson-system/rest/sysRole/deleteUserRole',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 查询角色
export function getById(data) {
  return request({
    url: '/rumpson-system/rest/sysRole/getById',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 查询角色
export function queryRole(data) {
  return request({
    url: '/rumpson-system/rest/sysRole/query',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 保存角色
export function saveRole(data) {
  return request({
    url: '/rumpson-system/rest/sysRole/save',
    method: 'post',
    data: Qs.stringify(data)
  })
}
// 删除角色
export function deleteRole(data) {
  return request({
    url: '/rumpson-system/rest/sysRole/delete',
    method: 'post',
    data: Qs.stringify(data)
  })
}
