import request from '@/utils/request'
const Qs = require('qs')

// 获取列表
export function list(data) {
  return request({
    url: '/rumpson-system/rest/sysApp/list',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 获取实例
export function page(data) {
  return request({
    url: '/rumpson-system/rest/sysApp/page',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 获取实例
export function getById(data) {
  return request({
    url: '/rumpson-system/rest/sysApp/getById',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 保存
export function save(data) {
  return request({
    url: '/rumpson-system/rest/sysApp/save',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 删除
export function del(data) {
  return request({
    url: '/rumpson-system/rest/sysApp/delete',
    method: 'post',
    data: Qs.stringify(data)
  })
}
