import request from '@/utils/request'
const Qs = require('qs')

// 根据角色获取列表
export function treeByUser(data) {
  return request({
    url: '/rumpson-system/rest/sysUserMenu/treeByUser',
    method: 'post',
    data: Qs.stringify(data)
  })
}
