<template>
  <div></div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {}
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  }
}
</script>
<style scoped lang="scss">
</style>
