<template>
  <el-dialog
    title="修改密码"
    :visible.sync="visible"
    width="500px"
    :before-close="handleClose"
  >
    <el-form ref="pwdForm" :model="currModel" :rules="formRules" autocomplete="on" label-width="150px" style="margin:0 auto;">
      <el-row>
        <el-col :span="24">
          <el-form-item prop="password" label="新密码：">
            <el-input id="password" v-model="currModel.password" :type="passwordType" placeholder="请输入密码" size="small">
              <span slot="prefix" class="show-pwd">
                <i class="icon iconfont-system mima" />
              </span>
              <span slot="suffix" class="show-pwd" @click="showPwd">
                <i :class="passwordType === 'password' ? 'icon iconfont-system bukejian' : 'icon iconfont-system kejian'" />
              </span>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose()">取 消</el-button>
      <el-button size="small" type="primary" @click="doSave()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import md5 from 'js-md5'
import { save } from '@/api/school-bus/student'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default: () => {
        return { id: null }
      }
    }
  },
  data() {
    return {
      currModel: {
        id: '',
        password: ''
      },
      formRules: {
        password: [{ required: true, trigger: 'blur', message: '请输入密码' }]
      },
      passwordType: 'password'
    }
  },
  watch: {
    model(newVal) {
      this.currModel = newVal
      this.currModel.password = ''
    }
  },
  methods: {
    handleClose() {
      this.$emit('closeDialog')
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
    },
    doSave() {
      this.$refs.pwdForm.validate((valid) => {
        if (valid) {
          this.currModel.children = []
          this.currModel.password = md5(this.currModel.password)
          save(this.currModel).then(res => {
            this.$message.success('保存成功！')
            this.$emit('closeDialog', true)
          }).catch((err) => {
            console.log(err)
            this.$message.error('保存失败！')
          })
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
  i {
    cursor: pointer;
  }
</style>
