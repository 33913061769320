<template>
  <span class="header-sub-system">
    <template v-if="style === 'dropdown'">
      <div class="spliter"></div>
      <el-dropdown class="header-dropdown" @command="handleCommand">
        <span class="header-sub-title">
          {{ subTitle }}<i class="icon iconfont-system zixitong"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="item in apps" :key="item.id" :command="item">
            <i :class="['icon', item.icon]" />
            {{ item.name }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </template>
    <template v-else>
      <div class="modules">
        <div v-for="item in appsReverse" :key="item.id" :class="'module' + (appName === item.name ? ' active': '')">
          <router-link :to="item.path">
            <i :class="['icon', item.icon]" />
            {{ item.name }}
          </router-link>
        </div>
      </div>
    </template>
  </span>
</template>

<script>
import store from '@/store'
export default {
  props: {
    appName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      apps: [],
      style: process.env.VUE_APP_HEADER_STYLE
    }
  },
  computed: {
    subTitle() {
      let titleTemp = '三维可视化'
      const currApps = this.apps.filter(item => item.name === this.appName)
      if (currApps && currApps.length > 0) {
        titleTemp = currApps[0].name
      }
      return titleTemp
    },
    appsReverse() {
      const res = this.apps
      return res.reverse()
    }
  },
  created() {
    this.apps = store.state.global.dynamicRoutes
  },
  methods: {
    handleCommand(command) {
      this.$router.push(command.path)
    }
  }
}
</script>
<style lang="scss" scoped>
  .header-sub-system {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: #fff;
    .spliter {
      width: 1px;
      height: 25px;
      background: #fff;
      margin: 17px 10px 17px 10px;
    }
    .header-dropdown {
      height: 60px;
      line-height: 60px;
      z-index: 99999;
      .header-sub-title {
        color: #fff;
        font-size: 16px;
        font-family: 宋体;
        font-weight: bold;
        cursor: pointer;
      }
      i {
        margin-left: 5px;
      }
      .expand1 {
        cursor: pointer;
      }
      .header-dropdown-list {
        height: 0px;
        width: 150px;
        left: 0;
        // border: dotted 1px #eee;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 0 0 6px 6px;
        box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
        overflow: hidden;
        transition: all 0.3s;
        font-size: 14px;
        color: #333333;
        font-weight: 400;
        line-height: 30px !important;
        margin-top: 0px;
        padding-left: -20px;
        li {
          width: 100%;
          height: 40px;
          line-height: 40px;
          // border-bottom: dotted 1px #eee;
          list-style-type: none;
          margin-left: 0;
          cursor: pointer;
          &:hover {
            background: #d6f1fa;
          }
          a {
            text-decoration: none;
          }
        }
      }
      &:hover {
        .dropdown-list {
          height: 200px;
        }
      }
    }
    .modules {
        // display: flex;
        // flex-direction: row-reveres;
      width: 100%;
      text-align: right;
      padding-right: 50px;
      .module {
        float: right;
        text-align: center;
        width: 140px;
        height: 58px;
        line-height: 58px;
        font-size: 18px;
        &:hover {
          background: #87CEEB;
          font-weight: bold;
          border-bottom: solid 2px #708CB8;
        }
        a {
          text-decoration: none;
          color: #fff;
        }
      }
      .active {
        background: #87CEEB;
        font-weight: bold;
        border-bottom: solid 2px #708CB8;
      }
    }
  }
</style>
