<template>
  <div class="content-container">
    <div class="toolbar-container">
      <el-button type="primary" size="mini" style="float: left;" @click="doSave()">
        <i class="icon iconfont-system baocun" />
        <span>保存</span>
      </el-button>
    </div>
    <div class="panel-container">
      <div class="panel-title">日期设置：</div>
      <div class="panel">
        <div style="margin-top: 20px">
          <div class="panel-title">常规订票日期：</div>
          <el-checkbox-group v-model="checkedDays" size="medium">
            <el-checkbox-button v-for="day in days" :key="day.id" :label="day.label">{{ day.label }}</el-checkbox-button>
          </el-checkbox-group>
          <span>&nbsp;</span>
          <div class="panel-title">其他订票日期：</div>
          <div class="except">
            <el-tag
              v-for="tag in otherOrderDates"
              :key="tag"
              closable
              :disable-transitions="false"
              @close="delOrderDate(tag)"
            >
              {{ tag }}
            </el-tag>
            <el-input
              v-if="orderDateInputVisible"
              ref="orderDateInputRef"
              v-model="orderDateInputValue"
              size="small"
              class="input-new-tag"
              @keyup.enter.native="handleInputOrderDate"
              @blur="handleInputOrderDate"
            >
            </el-input>
            <el-button v-else class="button-new-tag" size="small" @click="showOrderDateInput">+ 添加日期</el-button>
          </div>
          <span>&nbsp;</span>
          <div class="panel-title">其他非订票日期：</div>
          <div class="except">
            <el-tag
              v-for="tag in noOrderDates"
              :key="tag"
              closable
              :disable-transitions="false"
              @close="delNoOrderDate(tag)"
            >
              {{ tag }}
            </el-tag>
            <el-input
              v-if="noOrderDateInputVisible"
              ref="noOrderDateInputRef"
              v-model="noOrderDateInputValue"
              size="small"
              class="input-new-tag"
              @keyup.enter.native="handleInputNoOrderDate"
              @blur="handleInputNoOrderDate"
            >
            </el-input>
            <el-button v-else class="button-new-tag" size="small" @click="showNoOrderDateInput">+ 添加日期</el-button>
          </div>
        </div>
      </div>
      <div>&nbsp;</div>
      <div class="panel-title">时间设置：</div>
      <div class="panel">
        开始：
        <el-time-picker
          v-model="timeStart"
          :picker-options="{
            selectableRange: '00:00:00 - 23:59:59'
          }"
          placeholder="任意时间点"
        >
        </el-time-picker>
        结束：
        <el-time-picker
          v-model="timeEnd"
          arrow-control
          :picker-options="{
            selectableRange: '00:00:00 - 23:59:59'
          }"
          placeholder="任意时间点"
        >
        </el-time-picker>
      </div>
    </div>
  </div>
</template>

<script>
import searchBox from '@/views/components/search-box.vue'
import { get, save } from '@/api/school-bus/time'
export default {
  name: 'User',
  components: {
    searchBox
  },
  data() {
    return {
      currModel: {},
      loading: false,
      days: [
        { id: '星期一', label: '星期一', value: '星期一' },
        { id: '星期二', label: '星期二', value: '星期二' },
        { id: '星期三', label: '星期三', value: '星期三' },
        { id: '星期四', label: '星期四', value: '星期四' },
        { id: '星期五', label: '星期五', value: '星期五' },
        { id: '星期六', label: '星期六', value: '星期六' },
        { id: '星期日', label: '星期日', value: '星期日' }
      ],
      checkedDays: ['星期一', '星期二', '星期三', '星期四'],
      timeStart: new Date(2023, 1, 1, 0, 0, 0),
      timeEnd: new Date(2999, 12, 31, 23, 59, 59),
      otherOrderDates: ['2024-04-04', '2024-04-05', '2024-04-06'],
      noOrderDates: ['2024-04-04', '2024-04-05', '2024-04-06'],
      // 输入可见
      orderDateInputVisible: false,
      noOrderDateInputVisible: false,
      orderDateInputValue: '',
      noOrderDateInputValue: ''
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.loading = true
      this.checkedDays = []
      this.otherOrderDates = []
      this.noOrderDates = []
      get().then(res => {
        if (res && res.status === 200) {
          this.currModel = res.data
          // 常规订票日期
          if (this.currModel.normalDays) {
            this.currModel.normalDays.split(',').forEach((item) => {
              this.checkedDays.push(item)
            })
          }
          // 其他订票日期
          if (this.currModel.otherOrderDates) {
            this.currModel.otherOrderDates.split(',').forEach((item) => {
              this.otherOrderDates.push(item)
            })
          }
          // 其他非订票日期
          if (this.currModel.noOrderDates) {
            this.currModel.noOrderDates.split(',').forEach((item) => {
              this.noOrderDates.push(item)
            })
          }
          // 时间设置
          if (this.currModel.orderTimeStart) {
            this.timeStart = new Date('2024-04-05 ' + this.currModel.orderTimeStart)
          }
          if (this.currModel.orderTimeEnd) {
            this.timeEnd = new Date('2024-04-05 ' + this.currModel.orderTimeEnd)
          }
        }
        this.loading = false
      }).catch((err) => {
        console.error(err)
        this.loading = false
      })
    },
    doSave() {
      const params = {
        id: this.currModel.id,
        normalDays: this.checkedDays.join(','),
        otherOrderDates: this.otherOrderDates.join(','),
        noOrderDates: this.noOrderDates.join(','),
        orderTimeStart: this.$global.timeFormat(this.timeStart),
        orderTimeEnd: this.$global.timeFormat(this.timeEnd)
      }
      save(params).then((res) => {
        if (res && res.status === 200) {
          this.$message.success('保存成功！')
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error('保存失败！')
      })
      this.showEditDialog = true
    },
    // 删除
    delOrderDate(date) {
      this.otherOrderDates.splice(this.otherOrderDates.indexOf(date), 1)
    },
    delNoOrderDate(tag) {
      this.noOrderDates.splice(this.noOrderDates.indexOf(tag), 1)
    },
    // 输入可见控制
    showOrderDateInput() {
      this.orderDateInputVisible = true
      this.$nextTick(_ => {
        this.$refs.orderDateInputRef.$refs.input.focus()
      })
    },
    showNoOrderDateInput() {
      this.noOrderDateInputVisible = true
      this.$nextTick(_ => {
        this.$refs.noOrderDateInputRef.$refs.input.focus()
      })
    },
    // 输入确认
    handleInputOrderDate() {
      const val = this.orderDateInputValue
      if (val) {
        this.otherOrderDates.push(val)
      }
      this.orderDateInputVisible = false
      this.orderDateInputValue = ''
    },
    handleInputNoOrderDate() {
      const val = this.noOrderDateInputValue
      if (val) {
        this.noOrderDates.push(val)
      }
      this.noOrderDateInputVisible = false
      this.noOrderDateInputValue = ''
    }
  }
}
</script>
<style scoped lang="scss">
.panel-container {
  padding: 10px;
  .panel-title {
    font-size: 14px;
    font-weight: bold;
    height: 20px;
    line-height: 20px;
  }
  .panel {
    width: calc(100% - 20px);
    // height: 150px;
    border: solid 1px #ccc;
    border-radius: 5px;
    padding: 10px 0 10px 10px;
  }
}

.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
