import request from '@/utils/request'
const Qs = require('qs')

// 根据角色获取列表
export function listByRole(data) {
  return request({
    url: '/rumpson-system/rest/sysRoleMenu/listByRole',
    method: 'post',
    data: Qs.stringify(data)
  })
}

// 保存
export function save(data) {
  return request({
    url: '/rumpson-system/rest/sysRoleMenu/save',
    method: 'post',
    data: Qs.stringify(data)
  })
}
